export function addCouponToCookie(id, cookies, setCookie) {

    var nextYear = new Date();    
    nextYear.setDate(nextYear.getDate()+365);

    if (cookies.coupons) {
        const existing = cookies.coupons;

        if (!existing.includes(id)) {
            existing.push(id);
            setCookie('coupons', existing);
        }
    }
    else {
        setCookie('coupons', [id], {expires: nextYear});
    }
}

export function removeCouponFromCookie(id, cookies, setCookie) {

    var nextYear = new Date();    
    nextYear.setDate(nextYear.getDate()+365);
    
    const existing = cookies.coupons;

    let newIds = [];

    for (let i = 0; i < existing.length; i++) {
        if (existing[i] !== id) {
            newIds.push(existing[i])
        }
    }
    setCookie('coupons', newIds, {expires: nextYear});
}