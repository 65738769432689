import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";

export default function SyndicateLiveDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Kupongen tillagd till liverättning</DialogTitle>
            <DialogContent>
                <DialogContentText></DialogContentText>

                <Grid container justifyContent='center' sx={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Button onClick={handleClose} conponent="a" href="Live" variant="contained">Gå till liverättning</Button>
                </Grid>

            </DialogContent>

        </Dialog>
    );
}