import { ExpandMore } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Collapse, Grid, Typography } from "@mui/material";
import moment from "moment";
import '..//App.css';
import OddsTable from "./OddsTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { getDeadlineString, truncateString } from "../common/TextUtils";
import { getOddsForFixture } from "../common/BetslipApi";


function isSelected(legs, selectionTypeId, game) {

    if (legs === undefined || legs.length === 0)
        return false;

    return legs.some(l => l.FixtureId === game.id && l.SelectionTypeId === selectionTypeId);
}


export default function Fixture(props) {

    const [expanded, setExpanded] = React.useState(false);
    const [fixtureOdds, setFixtureOdds] = React.useState([]);
    const [stateId, setStateId] = React.useState(0);

    const handleExpandClick = () => {
        if (!props.couponLeg) {
            getOddsForFixture(setFixtureOdds, props.game.id)
        }
        setExpanded(!expanded);
    };
    
    let name = props.game.eventName ?? props.game.name;
    
    let deadlineString = getDeadlineString(props.game.deadlineUtc);

    if (!name) {
        return <></>
    }

    if (props.game.status === "LIVE") {
        deadlineString = props.game.minute + "'";
    }

    if (props.game.status === "HT") {
        deadlineString = "HT";
    }

    if (props.game.status === "FT") {
        deadlineString = "FT";
    }

    return (
        <Card className='fixture' elevation={props.elevation} sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? "#9cd7bc"
                    : theme.palette.grey[900]
        }
        }>
            <CardContent sx={{ pb: "0px" }}>
                <Grid container paddingTop={0} paddingBottom={0} flexGrow="1">
                    <Grid item xs={2} key="deadline" >
                        <Grid container height="100%" alignItems="center" justifyContent="start">
                            <Typography component="span">{deadlineString}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} key="teams" paddingLeft={1}>
                        {props.debug &&
                        <Grid item xs={12} key="id">
                        <Grid container alignItems="center" paddingBottom={0} >
                            <Typography sx={{ fontSize: '14px' }}>{props.game.id}</Typography>
                        </Grid>
                    </Grid>

                        }
                        <Grid item xs={12} key="home">
                            <Grid container alignItems="center" paddingBottom={0} >
                                <img alt="Home" src={props.game.homeTeam.logoPath} width="20px" height="20px"></img>
                                <Typography sx={{ fontSize: '14px' }}>{truncateString(props.game.homeTeam.name, props.truncate)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} key="away">
                            <Grid container alignItems="center">
                                <img alt="Away" src={props.game.awayTeam.logoPath} width="20px" height="20px"></img>
                                <Typography sx={{ fontSize: '14px' }}>{truncateString(props.game.awayTeam.name, props.truncate)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} key="scores" className='fixture-score'>
                        <Grid container height="100%" alignItems="center" >
                            <Grid item xs={12}>
                                <Grid container height="100%" alignItems="center" justifyContent="end">
                                    <Typography component="span" key="homeScore">{props.game.homeScore}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container height="100%" alignItems="center" justifyContent="end">
                                    <Typography component="span" key="awayScore">{props.game.awayScore}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {props.isOverview ? <></> :
                    <Grid container paddingTop={1} paddingBottom={0} justifyContent="center">
                        <Grid item key="selection" paddingLeft={0}>
                            <Typography sx={{ fontWeight: 700 }}>{props.game.selection}</Typography>
                        </Grid>
                    </Grid>
                }
            </CardContent>
            {props.game.status === "FT" ? <></> :
                <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid></Grid>
                    {props.isOverview ?
                        <Grid item>
                            <CardActions>
                                <Button variant={isSelected(props.legs, 1, props.game) ? "contained" : "outlined"} onClick={() => { props.addLeg(props.game, 1); setStateId(stateId + 1) }}>1</Button>
                                <Button variant={isSelected(props.legs, 2, props.game) ? "contained" : "outlined"} onClick={() => { props.addLeg(props.game, 2); setStateId(stateId + 1) }}>X</Button>
                                <Button variant={isSelected(props.legs, 3, props.game) ? "contained" : "outlined"} onClick={() => { props.addLeg(props.game, 3); setStateId(stateId + 1) }}>2</Button>
                            </CardActions>
                        </Grid> : <></>}

                    <CardActions>
                        <Typography component="span">Odds</Typography>
                        <ExpandMore
                            expand={expanded.toString()}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more">
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>
                </Grid>
            }
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <OddsTable odds={props.couponLeg ? props.game.odds : fixtureOdds} />
                </CardContent>
            </Collapse>
        </Card>
    );
}