import { Card, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import { getCouponReport } from '../common/BetslipApi';
import ReportCoupon from './ReportCoupon';

export default function Report(props) {

  const [couponReport, setCouponReport] = React.useState([]);
  const [reportType, setReportType] = React.useState("0");

  const handleChange = (event) => {
    setCouponReport([]);
    setReportType(event.target.value);
    getCouponReport(setCouponReport, props.profile?.googleId, reportType);
  };

  const refreshCoupons = () => {
    setCouponReport([]);    
    getCouponReport(setCouponReport, props.profile?.googleId, reportType);
  };

  const theme = useTheme();

  const wide = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    getCouponReport(setCouponReport, props.profile?.googleId, reportType);
  }, [props.profile?.googleId, reportType]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} id="coupons">
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Mina avgjorda spel</Typography>
            <Grid container direction={wide ? "row" : "column"}>
              <Grid item xs={8}>
                <Grid container direction="column">
                  <VictoryChart
                    width={600}
                    height={300}
                    theme={VictoryTheme.material}
                    maxDomain={{ y: couponReport.max }}
                    minDomain={{ y: couponReport.min }}
                  >
                    <VictoryLine
                      style={{
                        data: {
                          stroke: theme.palette.primary.main,
                          strokeWidth: 5,
                          strokeLinecap: "round"
                        }
                      }}

                      data={getChartData(couponReport.points)}
                    />
                    <VictoryAxis label="Dag" fixLabelOverlap style={{
                      axis: { stroke: "black", strokeWidth: 1 },
                      axisLabel: { fontSize: 12, padding: 30 },
                      grid: { strokeWidth: 0 },
                      ticks: { stroke: "black", size: 5 },
                      tickLabels: { fontSize: 10, padding: 5 }
                    }} />
                    <VictoryAxis dependentAxis label="Aggregerad vinst" style={{
                      axis: { stroke: "black", strokeWidth: 1 },
                      axisLabel: { fontSize: 12, padding: 30 },
                      grid: { strokeWidth: 0 },
                      ticks: { stroke: "black", size: 5 },
                      tickLabels: { fontSize: 10, padding: 5 }
                    }} />

                  </VictoryChart>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Period</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reportType}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={"0"} sel="true" control={<Radio />} label="Vecka" />
                      <FormControlLabel value={"1"} control={<Radio />} label="Månad" />

                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Card elevation={7} sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.grey[900],
                  color: 'white'
                }}>
                  <Grid container alignItems="center" direction="column">
                    <Typography sx={{ fontWeight: 600, fontSize: 40 }}>Senaste {reportType === "0" ? 'veckan' : 'månaden'}</Typography>
                    <Typography>Omsättning</Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 40 }}>{couponReport.turnover?.toFixed(0)} Kr</Typography>
                    <Typography>Vinst/Förlust</Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 40 }}>{(couponReport.payout - couponReport.turnover)?.toFixed(0)} Kr</Typography>
                    <Typography>Marginal</Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 40 }}>{(couponReport.margin * 100)?.toFixed(0)} %</Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>            
            <br></br>
            {
              couponList(couponReport.coupons, props.profile, props.removeCoupon, refreshCoupons)
            }
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

function getChartData(couponReport) {

  let data = [];

  if (couponReport === undefined)
    return data;

  for (const point of couponReport) {
    let datapoint = {
      x: point.label,
      y: point.value
    };
    data.push(datapoint);
  }

  return data;
}

function couponList(coupons, profile, removeCoupon, refreshCoupons) {

  function removeAndRefresh(id,profile){
    removeCoupon(id,profile);
    setTimeout(()=>{refreshCoupons();}, 1000);        
  }
  

  if (coupons === undefined) {
    return (<></>);
  }

  let finishedCoupons = coupons.filter(c => c.finished);

  return (

    <div>
      {finishedCoupons.map((coupon, index) => <ReportCoupon coupon={coupon} index={index} removeCoupon={removeAndRefresh} key={"coupon" + index} profile={profile} />)}
    </div>

  );
}

