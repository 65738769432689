import { Button, Card, Grid, Link, Typography } from '@mui/material';
import { Bonus } from '../model/bonus';

export default function BonusDisplay({ bonus, index }: { bonus: Bonus, index: number }) {

    let myFormat = Intl.NumberFormat("sv-SV");
    return (
        <Card className='coupon' elevation={7} key={index + 'coupon'} sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[900]

        }}>

            <Grid container direction="row">
                <Grid item xs={12} sm={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" height="100%" sx={{ pt: "10px", pb: "10px" }}>
                        <img alt="Logo1" src={bonus.image} width="auto" height={bonus.height}></img>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography
                        component="h5"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        {bonus.title}
                    </Typography>

                    <Grid container>
                        <Grid item xs={4}>
                            <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                <Grid item className='coupon-row' key='operator'>
                                    <div className='coupon-label'>Operatör:</div>
                                </Grid>
                                <Grid item className='coupon-row' key='min'>
                                    <div className='coupon-label'>Minsta insättning:</div>
                                </Grid>
                                <Grid item className='coupon-row' key='turnover'>
                                    <div className='coupon-label'>Omsättningskrav:</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                <Grid item className='coupon-row' key='operator'>
                                    <div className='coupon-value'>{bonus.bookmaker}</div>
                                </Grid>
                                <Grid item className='coupon-row' key='min'>
                                    <div className='coupon-value'>
                                        {myFormat.format(bonus.minDeposit)}
                                    </div>
                                </Grid>
                                <Grid item className='coupon-row' key='turnover'>
                                    <div className='coupon-value'>{bonus.turnover} ggr</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                                18+ <a href={bonus.link}>Regler & Villkor</a> gäller. Endast nya kunder. Spela ansvarsfullt <a href="https://stodlinjen.se/">Stödlinjen</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
                        <Link href={bonus.link} target="_blank">
                            <Button variant="contained" sx={{ mt: "10px", mb: "10px" }}>
                                Spela Här
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

            </Grid>


        </Card>
    );
}
