import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getDeadlineStringWithTime } from '../common/TextUtils';
import { useTheme } from "@emotion/react";

export default function Syndicate(props) {

    const theme = useTheme();
    let text = theme.palette.primary.contrastText;
    let selectedBackground = theme.palette.primary.main;

    let round = props.syndicate.roundEntry;
    let syndicate = props.syndicate;

    let deadlineString = getDeadlineStringWithTime(round.openTo);

    const nf = Intl.NumberFormat('sv-SV', { style: 'currency', currency: 'SEK', minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const styleCell = { padding: '5px' };

    return (
        <Grid item mt="20px" xs={12} md={4}>
            <Card elevation={7} key={props.index + 'coupon'} sx={{
                margin: '20px',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.secondary.main
                        : theme.palette.grey[900]
            }}>

                <Grid container direction='column' sx={{ marginLeft: '20px', marginTop: '20px' }}>
                    <Typography variant="h4" sx={{ fontSize: '1.5rem', fontWeight: 400, marginBottom: '20px' }}>
                        {syndicate.name}
                    </Typography>
                    <Typography component="span" variant='body'>
                        {deadlineString}
                    </Typography>
                    <Typography component="span" variant='body'>
                        Andelar: {syndicate.maxShareCount}
                    </Typography>
                    <Typography component="span" variant='body'>
                        Andelspris: {nf.format(syndicate.stakePerShareAmount)}
                    </Typography>
                    <br />
                </Grid>

                <TableContainer sx={{
                    padding: '5px',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.secondary.main
                            : theme.palette.grey[900]

                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styleCell}>Nr</TableCell>
                                <TableCell sx={styleCell}>Match</TableCell>
                                <TableCell sx={styleCell} align='center'>1</TableCell>
                                <TableCell sx={styleCell} align='center'>X</TableCell>
                                <TableCell sx={styleCell} align='center'>2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                round.fixtures.map((fixture, index) => {

                                    let leg = syndicate.legs[index];

                                    let homeSelected = leg.signs.indexOf('ONE') > -1;
                                    let drawSelected = leg.signs.indexOf('X') > -1;
                                    let awaySelected = leg.signs.indexOf('TWO') > -1;

                                    let homeBackground = homeSelected ? selectedBackground : undefined;
                                    let drawBackground = drawSelected ? selectedBackground : undefined;
                                    let awayBackground = awaySelected ? selectedBackground : undefined;

                                    let homeText = homeSelected ? text : undefined;
                                    let drawText = drawSelected ? text : undefined;
                                    let awayText = awaySelected ? text : undefined;

                                    return (
                                        <TableRow key={'row' + index}>
                                            <TableCell sx={styleCell}>{index + 1}</TableCell>
                                            <TableCell sx={styleCell}>{fixture.homeTeam.name}<br />{fixture.awayTeam.name}</TableCell>
                                            <TableCell align='center' sx={{ borderRadius: '4px', background: homeBackground, color: homeText, padding: '5px' }}>1</TableCell>
                                            <TableCell align='center' sx={{ borderRadius: '4px', background: drawBackground, color: drawText, padding: '5px' }}>X</TableCell>
                                            <TableCell align='center' sx={{ borderRadius: '4px', background: awayBackground, color: awayText, padding: '5px' }}>2</TableCell>
                                        </TableRow>
                                    );
                                }

                                )
                            }

                        </TableBody>
                    </Table>
                    <Grid container direction='row' justifyContent='center' sx={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Button variant='contained' id='buy_button' onClick={() => props.handleOpen(syndicate)}>Köp andel</Button>
                        {props.showLive && <Button sx={{ marginTop: '20px' }} variant='contained' onClick={() => props.addFile(props.syndicate)}>Lägg till i Liverättning</Button>}
                    </Grid>
                </TableContainer>
            </Card>
        </Grid>
    );
}
