import { Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import { getDeadlineStringWithTime } from '../common/TextUtils';
import PoolXFixture from './PoolXFixture';


export default function PoolXRound(props) {

    const wide = useMediaQuery('(min-width:600px)');

    let deadlineString = getDeadlineStringWithTime(props.round.deadline);
    let turnover = new Intl.NumberFormat('sv-SV', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0 }).format(props.round.turnover.toFixed(0));

    return (
        <Grid item mt="20px" xs={12}>
            <Card elevation={7} key={props.index + 'coupon'} sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? "#9cd7bc"
                        : theme.palette.grey[900]

            }}>

                <Grid sx={{ marginLeft: '20px', marginTop: '20px' }}>
                    <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
                        {props.round.name}
                    </Typography>

                    <Typography component="span" variant='body'>
                        {deadlineString}
                    </Typography>
                    <br />
                    <Typography component="span" variant='body'>
                        Omsättning: {turnover}
                    </Typography>
                </Grid>

                <TableContainer component={Paper} sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? "#9cd7bc"
                            : theme.palette.grey[900]

                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nr</TableCell>
                                <TableCell>Match</TableCell>
                                <TableCell>Startar</TableCell>
                                <TableCell>Streck % 1</TableCell>
                                <TableCell>Streck % X</TableCell>
                                <TableCell>Streck % 2</TableCell>
                                <TableCell>Marknad % 1</TableCell>
                                <TableCell>Marknad % X</TableCell>
                                <TableCell>Marknad % 2</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.round.fixtures.map((fixture, index) => <PoolXFixture fixture={fixture} index={index} key={"poolf" + index} />)}
                        </TableBody>
                    </Table>
                </TableContainer>



            </Card>
        </Grid>
    );
}
