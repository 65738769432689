import { Collapse, Grid, IconButton, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import { getDeadlineStringWithTime, getPercent } from '../common/TextUtils';
import OddsTable from './OddsTable';
import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';

export default function PoolXFixture(props) {

    const [open, setOpen] = React.useState(false);
    const wide = useMediaQuery('(min-width:600px)');

    let deadlineString = getDeadlineStringWithTime(props.fixture.deadline);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.fixture.order + 1}</TableCell>
                <TableCell>{props.fixture.homeName} - {props.fixture.awayName}</TableCell>
                <TableCell>{deadlineString}</TableCell>
                <TableCell>{getPercent(props.fixture.homeFraction)}</TableCell>
                <TableCell>{getPercent(props.fixture.drawFraction)}</TableCell>
                <TableCell>{getPercent(props.fixture.awayFraction)}</TableCell>
                <TableCell>{getPercent(props.fixture.marketHome)}</TableCell>
                <TableCell>{getPercent(props.fixture.marketDraw)}</TableCell>
                <TableCell>{getPercent(props.fixture.marketAway)}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>

            {open &&
                <TableRow>
                    <TableCell colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Grid container sx={{ marginLeft: '40px' }} direction='row'>
                                {props.fixture.odds &&

                                    <Grid container direction='row'>
                                        <Grid item sm={4}>
                                            <OddsTable odds={props.fixture.odds}></OddsTable>
                                        </Grid>
                                        <Grid item sx={{ marginLeft: '40px' }} sm={6}>
                                            <div dangerouslySetInnerHTML={{ __html: props.fixture.text }} />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

        </React.Fragment >
    );
}
