import { Avatar, Container, Grid, Paper, Typography } from '@mui/material';


export default function BlogPage({ name, bio, photo }: { name: string, bio: string, photo: string }) {

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Paper sx={{ backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[900],
                    p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
        <Grid container>
          <Avatar alt={name} src={photo} />
          <Typography sx={{marginLeft:"20px"}} variant="h5">{name}</Typography>
          <div dangerouslySetInnerHTML={{ __html: bio }} />
        </Grid>
      </Paper>
    </Container>
  );
}



