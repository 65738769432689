import { Avatar, Card, Grid, Typography } from '@mui/material';
import hp from "../images/hp.jpg"
import matte from "../images/matte.jpg"
import ola from "../images/thimren.jpg"

export default function About(props) {

  let styleBox = {
    maxWidth: "300px",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px"
  }

  let stylePaper = {
    margin: "10px",
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.grey[900]
  };

  let styleText = {
    padding: "20px",
  }

  let styleTextSmall = {
    fontSize: "14px"
  }

  return (

    <Grid container spacing={3} id="coupons">
      <Grid item xs={12}>

        <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Våra spelvinnare</Typography>

        <Grid container direction="row" sx={{ padding: "20px" }} justifyContent="space-around">

          <Card elevation={7} sx={stylePaper}>
            <Grid container sx={styleBox}>
              <Avatar
                alt="Ola"
                src={ola}
                sx={{ width: 100, height: 100 }}
              />
              <Typography sx={styleText} variant="h5">Ola Thimrén</Typography>
              <Typography sx={styleTextSmall} variant="body">
                Travintresserad sedan högstadiet och följt travet intensivt sedan dess. Med drygt 25 år som redaktör på travtidningen V75-Guiden har jag skaffat mig erfarenhet inom både spel och travsport. Eftersom jag har turen att få arbeta med min största hobby så tillbringas många timmar i lopparkivet.
                Har stor efterfarenhet av att komponera system och leta hästar med rätt spelvärde.
              </Typography>
            </Grid>
          </Card>

          <Card elevation={7} sx={stylePaper}>
            <Grid container sx={styleBox}>
              <Avatar
                alt="HP"
                src={hp}
                sx={{ width: 100, height: 100 }}
              />
              <Typography sx={styleText} variant="h5">Hans Petersson</Typography>
              <Typography sx={styleTextSmall} variant="body">
                100 matcher för a-lag i BP med en semifinal i Svenska Cupen och ett Hattrick mot Hammarby som roligaste minne.
                56-åriga HP har haft spelombud i 10 år och prickat in 13 rätt vid flertalet tillfällen på poolspel. Jag är otroligt taggad och
                motiverad att köra igång spelvinnare.se . Med den laguppställning vi har på spelvinnare är jag övertygad om att detta blir en
                framgångssaga. Etta på bollen 😄⚽Varmt välkomna.
              </Typography>
            </Grid>
          </Card>

          <Card elevation={7} sx={stylePaper}>
            <Grid container sx={styleBox}>
              <Avatar
                alt="Matte"
                src={matte}
                sx={{ width: 100, height: 100 }}
              />
              <Typography sx={styleText} variant="h5">Matte Forslund</Typography>
              <Typography sx={styleTextSmall} variant="body">
                VM-Guld, EM-Guld, SM-Guld, Europacup-Guld i innebandy.
                Jobbat med spel i 30 år.
                Varit spelexpert på Svenska spel i många år och även på ATG i några år.
                Nu kör vi igång spelvinnare.se för de som vill rygga bra spel utan att själva behöva jobba.😜
                Våra speltips och andelsspel kommer hålla högt spelvärde.💪

              </Typography>
            </Grid>
          </Card>

        </Grid>

      </Grid>
    </Grid>
  );
}
