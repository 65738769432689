import { BlogFilter } from "../model/blog";

export interface BlogTag {
  name: string;
  url?: string;
  image?: string;
  matches?: string[];
  selections?: string[];
  odds?: string;
  height?: number;
  width?: number;
}

export function getCategories(filter: BlogFilter): string[] {

  switch (filter) {
    case BlogFilter.POOL:
      return ["Topptipset", "Stryktipset", "Europatipset", "Power Play", "Bomben", "BIG9"]
    case BlogFilter.TRAV:
      return ["V86", "V75", "V64", "V65", "GS75"]
    case BlogFilter.SPORT:
      return ["Speltips-Fotboll", "Speltips - Hockey"]
  }

  return [];
}

export function getCategoryId(filter: BlogFilter): number[] {

  switch (filter) {
    case BlogFilter.POOL:
      return [899012613, 899012614, 899012612, 899012936, 899013882, 899015171]
    case BlogFilter.TRAV:
      return [899013164, 899013162, 899013163, 899011253, 899013161]
    case BlogFilter.SPORT:
      return [899011400, 899012848]
  }

  return [];
}



export function parseBlogTag(tagText: string): BlogTag {


  const decodeHtml = (html: string): string => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  };


  let blogTag: BlogTag = {
    name: ""
  };

  tagText = tagText.toLowerCase();
  const lines = tagText.split('\n');

  const bLine = lines.find(line => line.startsWith('b:'));
  if (bLine) {
    if (bLine.includes('comeon')) {
      blogTag.name = 'ComeOn';
      blogTag.image = "/images/comeon.png";
      blogTag.url = "https://media.comeon.com/tracking.php?tracking_code&aid=120009&mid=2797&sid=458933&pid=874";
      blogTag.height = 30;
      blogTag.width = 60;

    }
  }


  const mLine = lines.filter(line => line.startsWith('m:'));

  if (mLine) {
    blogTag.matches = [];
    blogTag.selections = [];
    mLine.map((line, index) => {
      let matchAndSelection = line.split(':')[1];
      let match = matchAndSelection.split('=')[0];
      let selection = matchAndSelection.split('=')[1];
      blogTag.matches?.push(decodeHtml(match));
      blogTag.selections?.push(decodeHtml(selection));
    });

    //blogTag.matches = mLine.map(line => decodeHtml(line.split(':')[1]));
  }

  const oLine = lines.find(line => line.startsWith('o:'));
  if (oLine) {
    blogTag.odds = oLine.split(':')[1];
  }

  return blogTag;
}