import { Grid, Paper, useMediaQuery } from '@mui/material';
import React from 'react';

export default function BannerColumn(props) {

  const wide = useMediaQuery('(min-width:1340px)');

  if (!wide) {
    return <></>;
  }

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: '300px', maxWidth: '300px' }}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item mt="30px">
          <iframe
            title="kungaslottet"
            allowtransparency="true"
            srcDoc="<html>
                  <body>
                    <script src='https://c.bannerflow.net/a/669795d320bec52a4d25a73f?did=66053b106333f54604d21edf&deeplink=on&redirecturl=https%3A%2F%2Ftrk.affiliates.videoslots.com%2Fo%2FMMwQXi%3Fr_id%3D49'>
                    </script>
                  </body>
                </html>"
            width="170"
            height="600"

            hspace="0"
            vspace="0"
            frameBorder="0"
            scrolling="no">
          </iframe>
        </Grid>

        <Grid item mt="30px">
          <iframe
            title="mrvegas"
            allowtransparency="true"
            srcDoc="<html>
                  <body>
                    <script src='https://c.bannerflow.net/a/664479ac6483c57d0e9e74e8?did=66053b106333f54604d21edf&deeplink=on&redirecturl=https%3A%2F%2Ftrk.affiliates.mrvegas.com%2Fo%2FduMzFb%3Fsite_id%3D7010394%26r_id%3D23'>
                    </script>
                  </body>
                </html>"
            width="250"
            height="250"

            hspace="0"
            vspace="0"
            frameBorder="0"
            scrolling="no">
          </iframe>
        </Grid>

        <Grid item mt="30px">
          <iframe
            title="videoslots"
            allowtransparency="true"
            srcDoc="<html>
                  <body>
                    <script src='https://c.bannerflow.net/a/663e0c521fa5c416f7faf3ff?did=66053b106333f54604d21edf&deeplink=on&redirecturl=https%3A%2F%2Ftrk.affiliates.videoslots.com%2Fo%2Fgm1iLE%3Fsite_id%3D7010394%26r_id%3D4'>
                    </script>
                  </body>
                </html>"
            width="170"
            height="600"

            hspace="0"
            vspace="0"
            frameBorder="0"
            scrolling="no">
          </iframe>
        </Grid>

        <Grid item mt="30px">
          <iframe
            title="comeon"
            id="8791" src="//embed.bannerflow.com/iframe?placement=64dc7da6ed777a29ca08e3a2&targetwindow=_blank&targeturl=https%3A%2F%2Fmedia.comeon.com%2Ftracking.php%3Ftracking_code%26aid%3D120009%26mid%3D8791%26sid%3D458933%26pid%3D394"

            width="250px"
            height="600px"
            scrolling="no"
            frameborder="0">

          </iframe>
        </Grid>

        <Grid item mt="30px">
          <iframe
            title='snabbare'
            id="7308"
            src="//embed.bannerflow.com/iframe?placement=622b555ee5523c30c5b9c5b9&targetwindow=_blank&targeturl=https%3A%2F%2Fmedia.snabbare.com%2Ftracking.php%3Ftracking_code%26aid%3D120009%26mid%3D7308%26sid%3D458933%26pid%3D633"            
            width="160px"
            height="600px"
            scrolling="no"
            frameborder="0"></iframe>
        </Grid>




      </Grid>
    </Paper>

  );
}
