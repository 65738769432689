import { Grid, Link } from "@mui/material";
import { Bookmakers } from "../common/Bookmakers";
import '..//App.css';
import { useLocation } from "react-router-dom";

export default function OddsTable(props) {

    const location = useLocation();

    if (props.odds === undefined) {
        return <></>;
    }

    let path = "";
    let parts = location.pathname.split('/');

    if (parts.length === 3) {
        path=".";
    }

    return (
        <Grid container paddingTop={1} paddingBottom={1} direction="column" className="odds-table">
            <Grid container key={"oddsRowTitle"} sx={{ fontWeight: 700, mb: "2px" }}>
                <Grid item xs={6} key={"nameTitle"}></Grid>
                <Grid item xs={2} key={"homeTitle"}>
                    <Grid container justifyContent="center" >1</Grid>
                </Grid>
                <Grid item xs={2} key={"drawTitle"}>
                    <Grid container justifyContent="center" >X</Grid>
                </Grid>
                <Grid item xs={2} key={"awayTitle"}>
                    <Grid container justifyContent="center" >2</Grid>
                </Grid>
            </Grid>

            {
                props.odds.map((odds, index) => {

                    let bookmaker = Bookmakers.find((b) => b.bookmaker === odds.marketPlaceName);

                    let home = odds.marketPlaceSelections.find(x => x.selectionTypeId === 1);
                    let draw = odds.marketPlaceSelections.find(x => x.selectionTypeId === 2);
                    let away = odds.marketPlaceSelections.find(x => x.selectionTypeId === 3);

                    if (!home || !draw || !away) {
                        return <div key={"empty" + index}></div>;
                    }

                    return (
                        <Grid container key={"oddsRow" + index}>
                            {bookmaker
                                ?
                                <Grid item xs={6}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start" height="100%" sx={{ pt: "0px", pb: "0px" }}>
                                        <Link href={bookmaker.link} target="_blank">
                                            <img alt={bookmaker.bookmaker} src={path + bookmaker.image} width="auto" height={bookmaker.height}></img>
                                        </Link>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={6} key={"name" + index}>{odds.marketPlaceName}</Grid>}

                            <Grid item xs={2} key={"home" + index}>
                                <Grid container justifyContent="center" alignItems="center" height="100%">
                                    {bookmaker ?
                                        <Link href={bookmaker.link} target="_blank">
                                            <>{home.odds.toFixed(2)}</>

                                        </Link> :
                                        <>
                                            {home.odds.toFixed(2)}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={2} key={"draw" + index}>
                                <Grid container justifyContent="center" alignItems="center" height="100%">
                                    {bookmaker ?
                                        <Link href={bookmaker.link} target="_blank">
                                            <>{draw.odds.toFixed(2)}</>

                                        </Link> :
                                        <>
                                            {draw.odds.toFixed(2)}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={2} key={"away" + index}>
                                <Grid container justifyContent="center" alignItems="center" height="100%">
                                    {bookmaker ?
                                        <Link href={bookmaker.link} target="_blank">
                                            <>{away.odds.toFixed(2)}</>

                                        </Link> :
                                        <>
                                            {away.odds.toFixed(2)}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>);

                })
            }
        </Grid>
    );
}