import { Box, Button, Card, Grid, TextField } from '@mui/material';
import Fixture from './Fixture';
import React from 'react';
import OddsTableMultiple from './OddsTableMultiple';


function handleSubmit(event, addCoupon, profile, legs, clearLegs) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let googleId = profile?.googleId;

    if (googleId === undefined) {
        googleId = "";
    }

    let newLegs = [];
    for (const leg of legs) {
        newLegs.push({
            FixtureId: leg.FixtureId,
            SelectionTypeId: leg.SelectionTypeId
        });
    }

    var addCouponReq = {
        Coupon: {
            Odds: data.get("odds").replace(",","."),
            Stake: data.get("insats").replace(",","."),
            Legs: newLegs,
            GoogleId: googleId
        }
    };

    addCoupon(addCouponReq, profile);
    clearLegs();
};


export default function NewCoupon(props) {

    return (
        <Card className='coupon' elevation={7} key={props.index + 'coupon'} sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[900],
                   minWidth: "300px" 

        }}>

            <Grid container alignItems="center" justifyContent="center">
                <Grid item width="100%">
                    {props.legs.map((leg, index) => <Fixture game={leg.game} index={index} key={"fixture" + index} truncate={10} />)}
                </Grid>
            </Grid>

            <Box component="form" noValidate onSubmit={ev => handleSubmit(ev, props.addCoupon, props.profile, props.legs, props.clearLegs)}>
                <Grid container direction="row" mt="20px" justifyContent="space-around">
                    <Grid item maxWidth="100px" >
                        <TextField
                            name="odds"
                            required
                            id="odds"
                            label="Odds"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item maxWidth="100px">
                        <TextField
                            required
                            id="insats"
                            label="Insats"
                            name="insats"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Lägg till
                </Button>

                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => props.clearLegs()}
                    sx={{ mb: 2 }}
                >
                    Avbryt
                </Button>
            </Box>

            {
                props.multipleMarketOdds ? <OddsTableMultiple odds={props.multipleMarketOdds} /> : <></>
            }

        </Card >
    );
}
