import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";

export default function SyndicateDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Köp andel</DialogTitle>
            <DialogContent>
                <DialogContentText>För att köpa andelar loggar du in på FSport och väljer andelsspel.</DialogContentText>

                <Grid container justifyContent='center' sx={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Button onClick={handleClose} conponent="a" href="https://www.fsport.se/#/lobby/poolx/" target="_blank" rel="noopener" variant="contained">Gå till Fsport</Button>
                </Grid>

            </DialogContent>

        </Dialog>
    );
}