import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { getCountries, getFixtures, getLeagues } from '../common/BetslipApi';
import Fixture from './Fixture';
import FixtureMenu from './FixtureMenu';


export default function FixtureView(props) {

  const [fixtures, setFixtures] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [leagues, setLeagues] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [selectedLeague, setSelectedLeague] = React.useState();
  const wide = useMediaQuery('(min-width:600px)');

  React.useEffect(() => {
    getFixtures(setFixtures);
    getCountries(setCountries);
    getLeagues(setLeagues);
  }, []);

  let sortedFixtures = fixtures;

  if (selectedCountry !== undefined) {
    let countryleagues = leagues.filter(l => l.countryId === selectedCountry.id).map(l => l.id);
    sortedFixtures = sortedFixtures.filter(f => countryleagues.some(l => l === f.leagueId));
  }

  if (selectedLeague !== undefined) {
    sortedFixtures = sortedFixtures.filter(f => f.leagueId === selectedLeague.id);
  }

  sortedFixtures = sortedFixtures.sort((a, b) => {
    return moment(a.deadlineUtc) - moment(b.deadlineUtc);
  }).slice(0, 20);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container direction={wide ? "row" : "column"}>
        <Grid item xs={4} pr="20px">
          <FixtureMenu countries={countries} leagues={leagues} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
            setSelectedLeague={setSelectedLeague} selectedLeague={selectedLeague} wide={wide} setFixtures={setFixtures}>
          </FixtureMenu>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3} id="coupons">
            <Grid item xs={12} maxWidth={wide ? "inherit" : "300px"}>

              {
                sortedFixtures.map((game, index) =>
                  <Fixture
                    elevation={3}
                    game={game}
                    index={index}
                    key={"fixture" + index}
                    addLeg={props.addLeg}
                    isOverview
                    legs={props.legs}
                    debug={props.debug}
                  />
                )
              }

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container >
  );
}
