import moment from "moment";

export function truncateString(str, num) {
  if (str.length <= num || num === undefined) {
    return str
  }
  return str.slice(0, num) + '...'
}

export function getDeadlineString(str) {
  let deadline = new moment(str)
  let deadlineString = deadline.format("HH:mm");
  let deadlineDate = deadline.format("DD");
  let today = new moment();
  let todayDate = today.format("DD");
  if (deadlineDate !== todayDate) {
    deadlineString = deadline.format("DD MMM");
  }
  return deadlineString;
}

export function getDeadlineStringWithTime(str) {
  let deadline = new moment(str)
  let deadlineString = deadline.format("HH:mm");
  let deadlineDate = deadline.format("DD");
  let today = new moment();
  let todayDate = today.format("DD");
  if (deadlineDate !== todayDate) {
    deadlineString = deadline.format("DD MMM HH:mm");
  }
  else {
    deadlineString = "Idag " + deadlineString;
  }
  return deadlineString;
}

export function getDateStringWithTime(str) {
  let deadline = new moment(str)
  let  deadlineString = deadline.format("DD MMM HH:mm");  
  return deadlineString;
}

export function getPercent(rate) {
  if(rate === null || rate === undefined){
    return '';
  }
  return (rate * 100).toFixed() + ' %';
}