import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import { getDateStringWithTime, getDeadlineStringWithTime } from '../common/TextUtils';
import { useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';

function handleClick(navigate, id) {
    navigate("/Live/" + id);
}

export default function PoolXCouponDisplay(props) {
    let navigate = useNavigate();
    let deadlineString = getDeadlineStringWithTime(props.couponDisplay.deadline);
    let createdString = getDateStringWithTime(props.couponDisplay.createdDateTime);

    return (

        <Grid sx={{ margin: '10px', textTransform: 'None' }}>

            <Card sx={{
                margin: '10px',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? "#9cd7bc"
                        : theme.palette.grey[900]

            }}>
                <CardHeader title={props.couponDisplay.name} subheader={deadlineString} />
                <CardContent>
                    <Grid>                                                                        
                        <Typography component="span" variant='body'>
                            {'Skapad: ' + createdString}
                        </Typography>                        
                    </Grid>
                    <Grid container justifyContent='center'>
                    <Button
                        sx={{mt:'20px'}}
                        variant="contained"
                        key={props.index + 'coupon'}
                        onClick={() => handleClick(navigate, props.couponDisplay.id)}>
                        Visa
                    </Button>
                    </Grid>
                </CardContent>

                <CardActions disableSpacing className='card-actions'>
                    <IconButton aria-label="delete" onClick={() => props.removeCoupon(props.couponDisplay.id)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>


    );
}
