import { Box, Container, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material";
import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Footer(props) {

    const location = useLocation();

    let path = "";
    let parts = location.pathname.split('/');

    if (parts.length === 3) {
        path=".";
    }

    const [checked, setChecked] = React.useState(props.darkMode);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.setDarkMode(event.target.checked)
    };

    let background = props.darkMode
        ? props.theme.palette.grey[800]
        : props.theme.palette.grey[200];

    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: background
            }}
        >
            <Container maxWidth="sm">
                <Grid item sx={{ mb: "20px" }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item sx={{ mr: "10px" }}>
                            <Grid container alignItems="center">
                                <img alt="Plus 18" src={path + "./images/plus18.png"} height="20px"></img>
                            </Grid>
                        </Grid>
                        <Typography>Spela ansvarsfullt  - Behöver du hjälp? Kontakta <a href="https://stodlinjen.se/">Stödlinjen</a></Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container flexDirection="row" alignItems="center" justifyContent="space-around">
                        <Grid item alignItems="center">
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={checked}
                                    onChange={handleChange}
                                />} label="Dark mode" />
                            </FormGroup>
                        </Grid>
                        <Grid item alignItems="center">
                            <Link to="/terms">Användarvillkor</Link>
                        </Grid>
                        <Grid item alignItems="center">
                            <a href="mailto:info@spelvinnare.se">info@spelvinnare.se</a>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <Copyright />
            </Container>
        </Box>)
};

export class Copyright extends Component {
    render() {
        return (
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                BAMM Communication AB
                {' ' + new Date().getFullYear()}
            </Typography>
        );
    }
}