import { Alert, Button, Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { addPoolXCoupon, deletePoolXCouponFromApi, getPoolXCoupons } from '../common/BetslipApi';
import BannerColumn from './BannerColumn';
import PoolXCouponDisplay from './PoolXCouponDisplay';

export default function Live(props) {
  const [poolXCoupons, setPoolXCoupons] = useState([]);
  const wide = useMediaQuery('(min-width:600px)');
  const [file, setFile] = useState();
  const [addedCouponId, setAddedCouponId] = useState([]);

  const googleId = props.profile?.googleId

  useEffect(() => {
    getPoolXCoupons(setPoolXCoupons, googleId);
  }, [googleId, addedCouponId]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  function removeCoupon(id) {
    deletePoolXCouponFromApi(id, setAddedCouponId);
  }

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    addPoolXCoupon(file, googleId, setAddedCouponId);
  }

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Helmet>
        <title>Spelvinnare.se - Liverättning för dina PoolX spel</title>
        <meta name="description" content="Ladda upp dina tipskuponger från PoolX här och följ dem live med procentuell chans att vinna. Liverättning för ditt tips" />
        <meta property="og:url" content="https://www.spelvinnare.se/live"></meta>
        <meta property="twitter:url" content="https://www.spelvinnare.se/live"></meta>        
      </Helmet>

      <Grid container id="coupons" justifyContent="center">
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: "20px" }}>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Liverättning</Typography>
            <br></br>
            <Typography variant="body">
              Ladda upp dina kuponger från PoolX här och följ dem live. Du spelar PoolX hos <a href="https://www.fsport.se/#/lobby/poolx/">FSport</a>
            </Typography>
            <br></br>

            {props.profile && props.profile.Name ?
              <></> :
              <Grid item xs={12} >
                <Alert severity="info">Logga in för att ladda upp kuponger</Alert>
                <br></br>
              </Grid>
            }

            <div>
              <input className="file" id="file" type="file" onChange={handleFileChange} />
              <Button disabled={googleId === undefined} variant="contained" sx={{ margin: "10px" }}>
                <label className="input-label" htmlFor="file">
                  Välj fil
                </label>
              </Button>

              <Button variant="contained" sx={{ margin: "10px" }} disabled={file === undefined} onClick={() => handleUploadClick()}>Ladda upp</Button>
              <Typography sx={{ margin: "10px" }} variant="body">{file && file.name}</Typography>
            </div>

            <br></br>
            <Grid container>
              {poolXCoupons.coupons && poolXCoupons.coupons.map((coupon, index) => <PoolXCouponDisplay removeCoupon={removeCoupon} couponDisplay={coupon} index={index} key={"display" + index} />)}
            </Grid>
          </Paper>
        </Grid>

        <Grid item>          
            <BannerColumn />          
        </Grid>


      </Grid>
    </Container>
  );
}



