import Moment from 'moment';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardActions, Grid, IconButton } from '@mui/material';
import Fixture from './Fixture';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ReportCoupon(props) {

    const theme = useTheme();
    let formatDate = Moment(props.coupon.deadline).format('YYYY-MM-DD HH:mm')
    let myFormat = Intl.NumberFormat("sv-SV");

    const fillColor = theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.secondary.main;

    const pathColor = theme.palette.mode === 'light'
        ? theme.palette.primary.light
        : theme.palette.secondary.dark;

    return (
        <Card className='coupon' elevation={7} key={props.index + 'coupon'} sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[900]

        }}>

            <Grid container direction="row">
                <Grid item xs={4}>
                    <Grid container paddingTop={1} paddingBottom={1} direction="column" marginLeft="20px">
                        <Grid item className='coupon-row' key='date'>
                            <div className='coupon-label'>Datum:</div>
                        </Grid>
                        <Grid item className='coupon-row' key='odds'>
                            <div className='coupon-label'>Odds:</div>
                        </Grid>
                        <Grid item className='coupon-row' key='stake'>
                            <div className='coupon-label'>Insats:</div>
                        </Grid>
                        <Grid item className='coupon-row' key='vinst'>
                            <div className='coupon-label'>Utbetalning:</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container paddingTop={1} paddingBottom={1} direction="column" marginLeft="30px">
                        <Grid item className='coupon-row' key='date'>
                            <div className='coupon-value'>{formatDate}</div>
                        </Grid>
                        <Grid item className='coupon-row' key='odds'>
                            <div className='coupon-value'>{props.coupon.odds.toFixed(2)}</div>
                        </Grid>
                        <Grid item className='coupon-row' key='stake'>
                            <div className='coupon-value'>
                                {myFormat.format(props.coupon.stake)}
                            </div>
                        </Grid>
                        <Grid item className='coupon-row' key='vinst'>
                            {props.coupon.finished
                                ?
                                (
                                    <div className='coupon-value'>
                                        {myFormat.format(props.coupon.payout.toFixed(0))}
                                    </div>
                                )
                                :
                                (
                                    <div className='coupon-value'>
                                        {myFormat.format((props.coupon.odds) * props.coupon.stake).toFixed(0)}
                                    </div>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" marginTop={1}>
                <Grid item xs={5} marginBottom={1}>
                    {props.coupon.finished
                        ?
                        (<Grid container justifyContent="center" marginBottom={1}>
                            {props.coupon.payout > 0 ?
                                <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
                                :
                                <HighlightOffIcon color="error" sx={{ fontSize: 40 }} />
                            }
                        </Grid>)
                        :
                        <CircularProgressbar
                            value={props.coupon.probability * 100}
                            text={`${(props.coupon.probability * 100).toFixed(0)}%`}
                            styles={{
                                path: {
                                    stroke: fillColor
                                },
                                trail: {
                                    stroke: pathColor
                                },
                                text: {
                                    fill: theme.palette.text.primary,
                                    fontSize: '16px',
                                }

                            }} />
                    }

                </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center">
                <Grid item width="100%">
                    {props.coupon.legs.map((game, index) => <Fixture game={game} index={index} key={"fixture" + index} couponLeg truncate={10} />)}
                </Grid>
            </Grid>
            <CardActions disableSpacing className='card-actions'>
                <IconButton aria-label="delete" onClick={() => props.removeCoupon(props.coupon.id, props.profile)}>
                    <DeleteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions>

        </Card >
    );
}
