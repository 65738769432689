import { AppBar, Avatar, Box, Container, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import Logo from "../images/logo-no-background.png"
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { AccountCircle, Logout } from "@mui/icons-material";
import BarChartIcon from '@mui/icons-material/BarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BoltIcon from '@mui/icons-material/Bolt';
import BlogIcon from '@mui/icons-material/DescriptionOutlined';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useState } from "react";


export default function TopMenu(props) {
    let navigate = useNavigate();
    const wide = useMediaQuery('(min-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(props.profile);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlelogOut = () => {
        setAnchorEl(null);
        googleLogout();
        props.logOut();
    };

    const handleSuccess = (resp) => {
        setAnchorEl(null);
        props.onSuccess(resp);
    };

    const select = (name) => {
        navigate("/" + name);
        props.setSelected(name);
    };

    const secondaryMenuText = {
        marginBottom: "10px"
    }


    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}>
            {props.profile && props.profile.Name ? (
                <div>
                    <MenuItem onClick={handlelogOut}>
                        <Logout fontSize="small" />
                        Logout
                    </MenuItem>
                </div>
            ) : (
                <div>
                    <MenuItem onClick={handleClose}>
                        <GoogleLogin
                            clientId={props.clientId}
                            onSuccess={(resp) => handleSuccess(resp)}
                            onError={props.onFailure}
                        />
                    </MenuItem>
                </div>)
            }
        </Menu>
    );

    return (

        <AppBar >
            <Container maxWidth="xl">
                <Toolbar className='toolbar'>
                    <Grid container direction="column">
                        <Grid container direction="row" justifyContent="center">
                            <IconButton color="inherit" onClick={() => navigate("/")}>
                                <img width={wide ? "300px" : "200px"} src={Logo} alt="Logo" />
                            </IconButton>
                            <Grid item >
                                <Grid container justifyContent="center">

                                    <MenuItem onClick={() => select("Andelar")} sx={{ fontSize: "14px" }}>
                                        <ListItemIcon sx={{ color: "inherit" }}>
                                            <GroupsOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>Andelar</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => select("Blog")} sx={{ fontSize: "14px" }}>
                                        <ListItemIcon sx={{ color: "inherit" }}>
                                            <BlogIcon />
                                        </ListItemIcon>
                                        <ListItemText>Speltips</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => select("Odds")} sx={{ fontSize: "14px" }}>
                                        <ListItemIcon sx={{ color: "inherit" }}>
                                            <BarChartIcon />
                                        </ListItemIcon>
                                        <ListItemText>Oddsjämförelse</ListItemText>
                                    </MenuItem>


                                    <MenuItem onClick={() => select("Pools")} sx={{ fontSize: "14px" }}>
                                        <ListItemIcon sx={{ color: "inherit" }}>
                                            <SportsSoccerOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>Tips</ListItemText>
                                    </MenuItem>


                                    {/*   <MenuItem onClick={() => navigate("/Syndicates")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText>Andelsspel</ListItemText>
                                </MenuItem> */}


                                    {/*  <MenuItem onClick={() => navigate("/PoolX")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText>PoolX</ListItemText>
                                </MenuItem>
 

                                    <MenuItem onClick={() => select("Live")} sx={{ fontSize: "14px" }}>
                                        <ListItemIcon sx={{ color: "inherit" }}>
                                            <BoltIcon />
                                        </ListItemIcon>
                                        <ListItemText>Liverättning</ListItemText>
                                    </MenuItem>
                                    */}



                                    {
                                        props.profile && props.Name ?
                                            <MenuItem onClick={() => select("Report")} sx={{ fontSize: "14px" }}>
                                                <ListItemIcon sx={{ color: "inherit" }}>
                                                    <StackedLineChartIcon />
                                                </ListItemIcon>
                                                <ListItemText>Avgjorda spel</ListItemText>
                                            </MenuItem> : <></>
                                    }

                                    <IconButton
                                        onClick={handleClick}
                                        size="large"
                                        edge="end"
                                        aria-label="account of current user"
                                        aria-haspopup="true"
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        color="inherit"
                                    >
                                        {
                                            props.profile && props.profile.Name ?
                                                (
                                                    <div>
                                                        <Avatar alt={props.profile.Name} src={props.profile.ImageUrl} />
                                                    </div>
                                                ) :
                                                (
                                                    <AccountCircle sx={{ fontSize: 40 }} />
                                                )
                                        }
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {props.selected === "Blog" &&
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <MenuItem onClick={() => navigate("/andelsspel")} sx={secondaryMenuText}>
                                    Poolspel sport
                                </MenuItem>
                                <Typography sx={{ ...{ margin: "0px 20px 0px 20px" }, ...secondaryMenuText }}>|</Typography>
                                <MenuItem onClick={() => navigate("/travsystem")} sx={secondaryMenuText}>
                                    Trav
                                </MenuItem>
                                <Typography sx={{ ...{ margin: "0px 20px 0px 20px" }, ...secondaryMenuText }}>|</Typography>
                                <MenuItem onClick={() => navigate("/sport")} sx={secondaryMenuText}>
                                    Sport
                                </MenuItem>
                            </Grid>
                        }
                        {props.selected === "Andelar" &&
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <MenuItem onClick={() => navigate("/andelar_sport")} sx={secondaryMenuText}>
                                    Sport
                                </MenuItem>
                                <Typography sx={{ ...{ margin: "0px 20px 0px 20px" }, ...secondaryMenuText }}>|</Typography>
                                <MenuItem onClick={() => navigate("/andelar_trav")} sx={secondaryMenuText}>
                                    Trav
                                </MenuItem>                                
                            </Grid>
                        }
                    </Grid>
                </Toolbar>
            </Container>
            {renderMenu}
        </AppBar>



    );
}