import { Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Helmet } from "react-helmet";
import { PoolInfos } from '../common/PoolInfos';
import BannerColumn from './BannerColumn';
import Pool from './Pool';


export default function Pools(props) {

  const wide = useMediaQuery('(min-width:600px)');

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Helmet>
        <title>Spelvinnare.se - Jämförelse mellan olika poolspel i Sverige - Tips, 1X2</title>
        <meta name="description" content="Jämförelse mellan olika poolspel, tips, 1X2, Stryktips i Sverige. PoolX, Topptipset, Stryktipset, Europatipset och Big 9" />
        <meta property="og:url" content="https://www.spelvinnare.se/pools"></meta>
        <meta property="twitter:url" content="https://www.spelvinnare.se/pools"></meta>        
      </Helmet>

      <Grid container id="coupons" justifyContent="center">
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Jämförelse mellan licencierade poolspel i Sverige</Typography>

            <br></br>
            <Typography variant="body">
              Spelvinnare.se har gått igenom reglerna och villkoren för de poolspel som är verksamma i Sverige. I översikten nedan kan du jämföra de alternativ
              som finns för spelare som vill tippa på fotboll.
            </Typography>

            <br></br>
            <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Vilken pool är bäst för spelarna?</Typography>
            <br></br>
            <Typography variant="body">
              Den viktigaste parametern för att bedöma om en pool är bra för spelaren är återbetalningen. Svenska spel har historiskt behållit en
              stor del av insatserna i sin egen ficka. I takt med att konkurrensen nu ökar med flera pooler sätts dessa marginaler under press.
              Det nyaste tillskottet PoolX har en återbetalning på hela 80% att jämföra med Stryktipsets låga 65%.
            </Typography>
            <br></br>
            <Typography variant="body">
              En annan aspekt för spelaren att värdera är hur stor poolen är. Är poolen för liten så får man väldigt sällan så mycket betalt för sitt spel som man är värd.
              Säg att man har lyckats pricka in en rad som händer en gång på en miljon och tar hem hela vinstpotten. Är då vinsten mindre än en miljon så har man gjort ett dåligt
              spel. I långa loppet kommer man alltid att förlora i en sådan pool. Av de pooler vi har undersökt nedan är det förutom de stora poolspelen hos Svenska Spel
              dvs Stryktipset, Europatipset och Topptipset bara PoolX som har tillräckligt stor pool för att man ska kunna räkna hem en teoretisk vinst i vissa fall.

            </Typography>
            <br></br>

            <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Är garantin värt något för mig som spelare?</Typography>
            <br></br>
            <Typography variant="body">
              Det finns lite olika upplägg med garantier i de olika spelen. För Stryktipset gäller att en ensam vinnare med 13 rätt alltid vinner minst 10
              miljoner kronor. För att täcka denna garanti tar Svenska Spel ut 8% av vinstpotten varje vecka. I realiteten är denna garanti inte värd någonting
              för spelarna eftersom omsättningen i normalfallet alltid räcker för att skapa en vinstpool på mer än 10 miljoner till 13 rätt. Istället används
              pengarna i garantifonden till olika marknadsföringskampanjer såsom extra jackpottar osv. Pengarna kommer alltså spelarna tillgodo men bara om du deltar
              i omgångarna med jackpot. I det nya spelet Fullträff lockar Svenska Spel med en garanti på 50 miljoner för alla rätt. De tar ut en skatt på 30% till
              fonderna som täcker denna garanti. Det låter ju bra med mycket pengar, men i realiteten kommer det vara oerhört sällan, om ens någon gång, en spelare
              prickar in en av de 549 miljarder möjliga raderna. Det understryks av att det hitills bara funnits rader med 11 rätt som mest.
            </Typography>
            <br></br>
            <Typography variant="body">
              Colossus upplägg med en garanti på 800 000 Kr i sitt spel med 15 matcher säger sig självt att den är alldeles otroligt för liten om man
              jämför med Svenska Spels garanti på 10 miljoner för 13 matcher. Dessutom är insatsen 2 Kr per rad för Colossus spel.
            </Typography>
            <br></br>

            <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Hur står sig PoolX mot Svenska Spel?</Typography>
            <br></br>
            <Typography variant="body">
              PoolX pooler är de enda förutom Svenska Spel som har tillräckligt mycket omsättning för att kunna ge tillräckligt bra vinster även
              för mer osannolika rader. Då återbetalningen är mycket större än Svenska Spel kommer det i de allra flesta fall vara större vinster hos PoolX.
              Prisplanen ser också annorlunda ut. Hos Svenska Spel går en relativt stor del av vinstpotten åt till de tråkiga vinsterna på 10 rätt. Dessa pengar
              åker hos PoolX istället upp till de högre vinstgrupperna.
            </Typography>
            <br></br>
            <Typography variant="body">
              Intressant är också upplägget med 12 matcher i PoolX Mix 12 som närmast kan jämföras med Europatipset. Eftersom poolen är lägre i dessa spel är
              det vanligt att alla rätt ger alldeles för lite pengar. Med 12 matcher krävs inte lika stor pott för att ge en bra vinst i förhållande till sannolikhet.
            </Typography>
            <br></br>

            <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Översikt</Typography>
            <br></br>
            <Grid container>
              {PoolInfos.map((pool, index) => <Pool pool={pool} index={index} key={"pool" + index} />)}
            </Grid>

          </Paper>
        </Grid>

        <Grid item>          
            <BannerColumn />          
        </Grid>


      </Grid>
    </Container>
  );
}



