import { Container, Grid, Paper, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { getPoolXRounds } from '../common/BetslipApi';
import BannerColumn from './BannerColumn';
import PoolXRound from './PoolXRound';

export default function PoolX(props) {
  const [poolXRounds, setPoolXRounds] = React.useState([]);  

  useEffect(() => {
    getPoolXRounds(setPoolXRounds);
  }, []);

  let sortedRounds = poolXRounds.sort((a, b) => moment(a.deadline) - moment(b.deadline));

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Helmet>
        <title>Spelvinnare.se - Närmaste PoolX omgångarna med streckfördelning</title>
        <meta name="description" content="Streckfördelning för veckans tipskuponger i PoolX, Sveriges bästa tips för fotboll. Odds för alla matcher." />
        <meta property="og:url" content="https://www.spelvinnare.se/poolx"></meta>
        <meta property="twitter:url" content="https://www.spelvinnare.se/poolx"></meta>       
      </Helmet>

      <Grid container id="coupons" justifyContent="center">
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: "20px" }}>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>PoolX</Typography>

            <br></br>
            <Typography variant="body">
              Här kan du se de närmaste omgångarna i Sveriges nya poolspel, PoolX. Du spelar PoolX hos <a href="https://www.fsport.se/#/lobby/poolx/" target="_blank">FSport</a>.
              Om du vill spela tillsammans hittar du det i vår avdelning för <a href="/syndicates">andelsspel</a>.
            </Typography>

            <Grid container>
              {sortedRounds.length > 0 ?
                sortedRounds.map((round, index) => <PoolXRound round={round} index={index} key={"pool" + index} />) :
                <Grid item mt="20px" xs={12}>
                  <Skeleton sx={{ marginTop: '20px', marginLeft: '5px', marginRight: '5px' }} variant="rounded" height={600} />
                  <Skeleton sx={{ marginTop: '20px', marginLeft: '5px', marginRight: '5px' }} variant="rounded" height={600} />
                  <Skeleton sx={{ marginTop: '20px', marginLeft: '5px', marginRight: '5px' }} variant="rounded" height={600} />
                </Grid>
              }
            </Grid>

          </Paper>
        </Grid>

        <Grid item>          
            <BannerColumn />          
        </Grid>


      </Grid>
    </Container>
  );
}



