import { Box, Card, CardContent, CardHeader, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getPoolXCoupon } from '../common/BetslipApi';
import BannerColumn from './BannerColumn';
import { useParams } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTheme } from '@mui/material/styles';
import LiveCouponPoolXFixture from './LiveCouponPoolXFixture';
import { Helmet } from "react-helmet";


export function currentStanding(params) {
  if (params.isSummary) {
    return "";
  }
  return params.homeScore + " - " + params.awayScore;
}

function getBackground(rowSign, correct) {
  if (rowSign === correct) {
    return { backgroundColor: 'lightgreen', padding: '1px' };
  }
  return { backgroundColor: 'none', padding: '1px' };
}

function getStyleForName(game) {
  if (game.isSummary || game.isProbability) {
    return { fontWeight: 500 };
  }
  return {};
}

function currencyFormatter(number) {
  return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", maximumFractionDigits: 0 }).format(
    number,
  );
}

function rad(game, order, rader) {

  if (order > rader.length - 1) {
    return "";
  }

  if (game.isSummary) {
    return rader[order].numCorrect;
  }

  if (game.isProbability) {
    return (rader[order].probabilityAll * 100).toFixed(0);
  }

  switch (game.gameOrder) {
    case 0:
      return rader[order].sign01;
    case 1:
      return rader[order].sign02;
    case 2:
      return rader[order].sign03;
    case 3:
      return rader[order].sign04;
    case 4:
      return rader[order].sign05;
    case 5:
      return rader[order].sign06;
    case 6:
      return rader[order].sign07;
    case 7:
      return rader[order].sign08;
    case 8:
      return rader[order].sign09;
    case 9:
      return rader[order].sign10;
    case 10:
      return rader[order].sign11;
    case 11:
      return rader[order].sign12;
    case 12:
      return rader[order].sign13;
    default:
      return "";
  }
}

export default function LiveCoupon(props) {
  const [poolXCoupon, setPoolXCoupon] = useState([]);
  const [fetching, setFetching] = useState(false);
  const wide = useMediaQuery('(min-width:600px)');
  const routeParams = useParams();
  const theme = useTheme();

  const id = routeParams.id;

  useEffect(() => {
    const timer = setInterval(() => {
      getPoolXCoupon(setPoolXCoupon, setFetching, id)
    }, 60000);
    return () => {
      clearInterval(timer);
    };

  }, [id]);

  if (!fetching) {
    getPoolXCoupon(setPoolXCoupon, setFetching, id);
  }

  const fillColor = theme.palette.mode === 'light'
    ? theme.palette.primary.main
    : theme.palette.secondary.main;

  const pathColor = theme.palette.mode === 'light'
    ? theme.palette.primary.light
    : theme.palette.secondary.dark;

  let games = [];
  let rows = [];
  let i = 0;
  if (poolXCoupon.couponStatus) {
    poolXCoupon.couponStatus.games.forEach(game => {
      games.push({
        id: i,
        gameName: game.gameName,
        gameOrder: game.gameOrder + 1,
        isSummary: false,
        homeScore: game.homeScore,
        awayScore: game.awayScore,
        gameStatus: game.gameStatus,
        currentSign: game.currentSign,
        pickHome: game.pickHome,
        pickDraw: game.pickDraw,
        pickAway: game.pickAway,
        ownHome: game.ownHome,
        ownDraw: game.ownDraw,
        ownAway: game.ownAway,
      })
      i++;
    });


    let j = 0;

    poolXCoupon.couponStatus.games.forEach(game => {
      rows.push({
        id: j,
        gameName: game.gameName,
        gameOrder: game.gameOrder,
        isSummary: false,
        homeScore: game.homeScore,
        awayScore: game.awayScore,
        gameStatus: game.gameStatus,
        currentSign: game.currentSign,
        rad0: rad(game, 0, poolXCoupon.couponStatus.rows),
        rad1: rad(game, 1, poolXCoupon.couponStatus.rows),
        rad2: rad(game, 2, poolXCoupon.couponStatus.rows),
        rad3: rad(game, 3, poolXCoupon.couponStatus.rows),
        rad4: rad(game, 4, poolXCoupon.couponStatus.rows),
        rad5: rad(game, 5, poolXCoupon.couponStatus.rows),
        rad6: rad(game, 6, poolXCoupon.couponStatus.rows),
        rad7: rad(game, 7, poolXCoupon.couponStatus.rows),
        rad8: rad(game, 8, poolXCoupon.couponStatus.rows),
        rad9: rad(game, 9, poolXCoupon.couponStatus.rows),
        rad10: rad(game, 10, poolXCoupon.couponStatus.rows),
        rad11: rad(game, 11, poolXCoupon.couponStatus.rows),
        rad12: rad(game, 12, poolXCoupon.couponStatus.rows),
        rad13: rad(game, 13, poolXCoupon.couponStatus.rows),
        rad14: rad(game, 14, poolXCoupon.couponStatus.rows),
        rad15: rad(game, 15, poolXCoupon.couponStatus.rows),
        rad16: rad(game, 16, poolXCoupon.couponStatus.rows),
        rad17: rad(game, 17, poolXCoupon.couponStatus.rows),
        rad18: rad(game, 18, poolXCoupon.couponStatus.rows),
        rad19: rad(game, 19, poolXCoupon.couponStatus.rows),
      })
      j++;
    });

    const summaryGame = { isSummary: true };
    rows.push({
      id: j,
      isSummary: true,
      gameName: 'Antal rätt',
      rad0: rad(summaryGame, 0, poolXCoupon.couponStatus.rows),
      rad1: rad(summaryGame, 1, poolXCoupon.couponStatus.rows),
      rad2: rad(summaryGame, 2, poolXCoupon.couponStatus.rows),
      rad3: rad(summaryGame, 3, poolXCoupon.couponStatus.rows),
      rad4: rad(summaryGame, 4, poolXCoupon.couponStatus.rows),
      rad5: rad(summaryGame, 5, poolXCoupon.couponStatus.rows),
      rad6: rad(summaryGame, 6, poolXCoupon.couponStatus.rows),
      rad7: rad(summaryGame, 7, poolXCoupon.couponStatus.rows),
      rad8: rad(summaryGame, 8, poolXCoupon.couponStatus.rows),
      rad9: rad(summaryGame, 9, poolXCoupon.couponStatus.rows),
      rad10: rad(summaryGame, 10, poolXCoupon.couponStatus.rows),
      rad11: rad(summaryGame, 11, poolXCoupon.couponStatus.rows),
      rad12: rad(summaryGame, 12, poolXCoupon.couponStatus.rows),
      rad13: rad(summaryGame, 13, poolXCoupon.couponStatus.rows),
      rad14: rad(summaryGame, 14, poolXCoupon.couponStatus.rows),
      rad15: rad(summaryGame, 15, poolXCoupon.couponStatus.rows),
      rad16: rad(summaryGame, 16, poolXCoupon.couponStatus.rows),
      rad17: rad(summaryGame, 17, poolXCoupon.couponStatus.rows),
      rad18: rad(summaryGame, 18, poolXCoupon.couponStatus.rows),
      rad19: rad(summaryGame, 19, poolXCoupon.couponStatus.rows),
    })

    const probGame = { isProbability: true };
    rows.push({
      id: j + 1,
      isSummary: true,
      gameName: 'Sannolikhet',
      rad0: rad(probGame, 0, poolXCoupon.couponStatus.rows),
      rad1: rad(probGame, 1, poolXCoupon.couponStatus.rows),
      rad2: rad(probGame, 2, poolXCoupon.couponStatus.rows),
      rad3: rad(probGame, 3, poolXCoupon.couponStatus.rows),
      rad4: rad(probGame, 4, poolXCoupon.couponStatus.rows),
      rad5: rad(probGame, 5, poolXCoupon.couponStatus.rows),
      rad6: rad(probGame, 6, poolXCoupon.couponStatus.rows),
      rad7: rad(probGame, 7, poolXCoupon.couponStatus.rows),
      rad8: rad(probGame, 8, poolXCoupon.couponStatus.rows),
      rad9: rad(probGame, 9, poolXCoupon.couponStatus.rows),
      rad10: rad(probGame, 10, poolXCoupon.couponStatus.rows),
      rad11: rad(probGame, 11, poolXCoupon.couponStatus.rows),
      rad12: rad(probGame, 12, poolXCoupon.couponStatus.rows),
      rad13: rad(probGame, 13, poolXCoupon.couponStatus.rows),
      rad14: rad(probGame, 14, poolXCoupon.couponStatus.rows),
      rad15: rad(probGame, 15, poolXCoupon.couponStatus.rows),
      rad16: rad(probGame, 16, poolXCoupon.couponStatus.rows),
      rad17: rad(probGame, 17, poolXCoupon.couponStatus.rows),
      rad18: rad(probGame, 18, poolXCoupon.couponStatus.rows),
      rad19: rad(probGame, 19, poolXCoupon.couponStatus.rows),
    })

  }



  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Helmet>
        <title>Spelvinnare.se - Liverättning för dina PoolX spel</title>
        <meta name="description" content="Liverättning för ditt spel hos PoolX" />
        <meta property="og:url" content={"https://www.spelvinnare.se/live/" + id}></meta>
        <meta property="twitter:url" content={"https://www.spelvinnare.se/live/" + id}></meta>        
      </Helmet>

      {!wide ? <><br></br><br></br><br></br></> : <></>}

      <Grid container id="coupons" direction={wide ? "row" : "column"}>

        <Grid item sm={9} xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: "20px" }}>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Liverättning</Typography>
            <br></br>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>{id}</Typography>
            <br></br>

            <Grid container sx={{ justifyContent: "center" }} >
              {poolXCoupon.couponStatus &&

                <Grid item xs={12}>
                  <Card sx={{ margin: "10px", backgroundColor: theme.palette.secondary.main }}>
                    <CardHeader subtitle={poolXCoupon.couponStatus.deadline} title={poolXCoupon.poolXRound.name} />
                    <CardContent sx={{ margin: "10px" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width='50'>#</TableCell>
                            <TableCell >Match</TableCell>
                            <TableCell >Ställning</TableCell>
                            <TableCell >Tid</TableCell>
                            <TableCell >Tecken</TableCell>
                            <TableCell >Folket Hemma</TableCell>
                            <TableCell >Folket Oavgj</TableCell>
                            <TableCell >Folket Borta</TableCell>
                            <TableCell >System Hemma</TableCell>
                            <TableCell >System Oavgj</TableCell>
                            <TableCell >System Borta</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            games.map((g, i) => {
                              return (
                                <LiveCouponPoolXFixture key={'f' + i} game={g} round={poolXCoupon.poolXRound} />
                              );
                            })
                          }
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {poolXCoupon.couponStatus &&
                <Grid item xs={12}>
                  <Card sx={{ margin: "10px", backgroundColor: theme.palette.secondary.main }}>
                    <CardHeader title="Bästa raderna" />
                    <CardContent sx={{ margin: "10px" }}>
                      <Table sx={{ margin: "1px", padding: "1px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell width='50'>#</TableCell>
                            <TableCell >Match</TableCell>
                            <TableCell >Ställning</TableCell>
                            <TableCell >Tid</TableCell>
                            <TableCell >Tecken</TableCell>
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                            <TableCell sx={{ padding: "1px" }} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            rows.map(g => {
                              return (
                                <TableRow key={g.id}>
                                  <TableCell>{g.gameOrder}</TableCell>
                                  <TableCell sx={getStyleForName(g)}>{g.gameName}</TableCell>
                                  <TableCell>{currentStanding(g)}</TableCell>
                                  <TableCell>{g.gameStatus}</TableCell>
                                  <TableCell>{g.currentSign}</TableCell>
                                  <TableCell sx={getBackground(g.rad0, g.currentSign)}>{g.rad0}</TableCell>
                                  <TableCell sx={getBackground(g.rad1, g.currentSign)}>{g.rad1}</TableCell>
                                  <TableCell sx={getBackground(g.rad2, g.currentSign)}>{g.rad2}</TableCell>
                                  <TableCell sx={getBackground(g.rad3, g.currentSign)}>{g.rad3}</TableCell>
                                  <TableCell sx={getBackground(g.rad4, g.currentSign)}>{g.rad4}</TableCell>
                                  <TableCell sx={getBackground(g.rad5, g.currentSign)}>{g.rad5}</TableCell>
                                  <TableCell sx={getBackground(g.rad6, g.currentSign)}>{g.rad6}</TableCell>
                                  <TableCell sx={getBackground(g.rad7, g.currentSign)}>{g.rad7}</TableCell>
                                  <TableCell sx={getBackground(g.rad8, g.currentSign)}>{g.rad8}</TableCell>
                                  <TableCell sx={getBackground(g.rad9, g.currentSign)}>{g.rad9}</TableCell>
                                  <TableCell sx={getBackground(g.rad10, g.currentSign)}>{g.rad10}</TableCell>
                                  <TableCell sx={getBackground(g.rad11, g.currentSign)}>{g.rad11}</TableCell>
                                  <TableCell sx={getBackground(g.rad12, g.currentSign)}>{g.rad12}</TableCell>
                                  <TableCell sx={getBackground(g.rad13, g.currentSign)}>{g.rad13}</TableCell>
                                  <TableCell sx={getBackground(g.rad14, g.currentSign)}>{g.rad14}</TableCell>
                                  <TableCell sx={getBackground(g.rad15, g.currentSign)}>{g.rad15}</TableCell>
                                  <TableCell sx={getBackground(g.rad16, g.currentSign)}>{g.rad16}</TableCell>
                                  <TableCell sx={getBackground(g.rad17, g.currentSign)}>{g.rad17}</TableCell>
                                  <TableCell sx={getBackground(g.rad18, g.currentSign)}>{g.rad18}</TableCell>
                                  <TableCell sx={getBackground(g.rad19, g.currentSign)}>{g.rad19}</TableCell>
                                </TableRow>
                              );
                            }
                            )
                          }
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {poolXCoupon.couponStatus &&
                <Grid item xs={12} sm={6}>
                  <Card sx={{ margin: "10px", backgroundColor: theme.palette.secondary.main }}>
                    <CardHeader title="Utdelning" />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">Rätt</TableCell>
                            <TableCell align="right">Utdelning</TableCell>
                            <TableCell align="right">Antal</TableCell>
                            <TableCell align="right">Egna Rader</TableCell>
                            <TableCell align="right">Egen Utdelning</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{
                          poolXCoupon.couponStatus &&
                          poolXCoupon.couponStatus.prizes.map(function (utdelning, index) {
                            return (
                              <TableRow key={index}>
                                <TableCell align="right">{utdelning.numCorrect}</TableCell>
                                <TableCell align="right">{currencyFormatter(utdelning.win)}</TableCell>
                                <TableCell align="right">{utdelning.numWin.toFixed(0)}</TableCell>
                                <TableCell align="right">{utdelning.ownNumWin}</TableCell>
                                <TableCell align="right">{currencyFormatter(utdelning.ownWin)}</TableCell>
                              </TableRow>
                            );
                          })
                        }
                          <TableRow key="total">
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell sx={{ fontWeight: 500 }} align="right">{poolXCoupon.couponStatus && currencyFormatter(poolXCoupon.couponStatus.totalSystemWin)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {poolXCoupon.couponStatus &&
                <Grid item xs={12} sm={3}>
                  <Card sx={{ margin: "10px", backgroundColor: theme.palette.secondary.main }}>
                    <CardHeader title="Chans till alla rätt" />
                    <CardContent>
                      <Box height='200px' width='200px'>
                        <CircularProgressbar
                          value={poolXCoupon.couponStatus.totalProbability * 100}
                          text={`${(poolXCoupon.couponStatus.totalProbability * 100).toFixed(0)}%`}
                          styles={{
                            path: {
                              stroke: fillColor
                            },
                            trail: {
                              stroke: pathColor
                            },
                            text: {
                              fill: theme.palette.text.primary,
                              fontSize: '16px',
                            }

                          }} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {poolXCoupon.couponStatus &&
                <Grid item sm={3} xs={12}>
                  <Card sx={{ margin: "10px", backgroundColor: theme.palette.secondary.main }}>
                    <CardHeader title="Antal rader" />
                    <CardContent>
                      <Grid alignItems='center' justifyContent='center' width='100%' container>
                        <Typography sx={{ fontWeight: 600, fontSize: 40 }}>{poolXCoupon.couponStatus.numberOfRows}</Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              }

            </Grid>
          </Paper>
        </Grid>

        <Grid item>          
            <BannerColumn />          
        </Grid>

      </Grid>
    </Container>
  );
}



