import { Alert, Container, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import BannerColumn from './BannerColumn';
import Syndicate from './Syndicate';
import SyndicateDialog from './SyndicateDialog';
import moment from 'moment';
import { addPoolXCoupon } from '../common/BetslipApi';
import SyndicateLiveDialog from './SyndicateLiveDialog';

export default function Syndicates(props) {

  let [poolXSyndicates, setPoolXSyndicates] = useState(undefined);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [liveDialogOpen, setLiveDialogOpen] = useState(false);
  let [couponId, setCouponId] = useState("");

  useEffect(() => {

    fetch('https://publicdata.live.poolxbet.com/syndicates/?operatorId=FSPORT').then((response) => {
      return response.json();
    }).then(((json) => {
      setPoolXSyndicates(json.syndicates);
    }))
  }, []
  )

  let googleId = props.profile?.googleId

  const handleClickOpen = (syndicate) => {

    setDialogOpen(true);
    window.gtag("event", "purchase", {
      value: syndicate.stakePerShareAmount,
      currency: "SEK",
      coupon: syndicate.name
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    setLiveDialogOpen(false);
  };

  function getAllCombinations(arrays) {
    function combine(index, currentCombination) {
      if (index === arrays.length) {
        combinations.push(currentCombination.slice());
        return;
      }

      for (const value of arrays[index]) {
        currentCombination.push(value);
        combine(index + 1, currentCombination);
        currentCombination.pop();
      }
    }

    const combinations = [];
    combine(0, []);

    return combinations;
  }

  const addFile = (syndicate) => {
    let legs = syndicate.legs;
    let myArray = [];
    legs.forEach(function (leg) {

      let mySmallArray = [];

      if (leg.signs.indexOf('ONE') > -1) {
        mySmallArray.push('1');
      }
      if (leg.signs.indexOf('X') > -1) {
        mySmallArray.push('X');
      }
      if (leg.signs.indexOf('TWO') > -1) {
        mySmallArray.push('2');
      }
      myArray.push(mySmallArray);
    })

    let combinations = getAllCombinations(myArray);

    let myText = "" + syndicate.roundEntry.id + "\r\n";

    combinations.forEach(function (row) {

      myText += "E," + row.join(',') + "\r\n";

    })

    const file = new File([myText], "example.txt", { type: "text/plain" });

    if (googleId !== undefined) {
      addPoolXCoupon(file, googleId, setCouponId)
      setLiveDialogOpen(true);
    }
  };

  let now = new moment();

  poolXSyndicates = poolXSyndicates?.sort((a, b) => {
    return moment(a.roundEntry.openTo) - moment(b.roundEntry.openTo)
  })

  let syndicatesToShow = poolXSyndicates?.filter(s => moment(s.roundEntry.openTo) > now);

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Helmet>
        <title>Spelvinnare.se - Andelsspel</title>
        <meta name="description" content="Andelsspel spela tillsammans för att öka vinstchanserna. Köp färdiga andelsspel till PoolX utan extra avgifter." />
        <meta property="og:url" content="https://www.spelvinnare.se/syndicates"></meta>
        <meta property="twitter:url" content="https://www.spelvinnare.se/syndicates"></meta>
      </Helmet>

      <Grid container id="coupons" justifyContent="center">
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Andelsspel</Typography>

            <br></br>
            <Typography variant="body">
              Spelvinnare.se kan, som första site i Sverige, nu erbjuda andelsspel på PoolX. Genom att spela tillsammans är du mer på ett större system med
              större chans att vinna. Du köper dina andelar helt utan extra avgifter. När du har köpt kupongen vet du att du har din andel, även om inte alla andelar säljs.
            </Typography>

            {props.profile && props.profile.Name ?
              <></> :
              <Grid item xs={12} >
                <br></br>
                <Alert severity="info">Logga in för att ladda upp andelsspelet till liverättning</Alert>
                <br></br>
              </Grid>
            }

            <Grid container direction='row'>

              {syndicatesToShow && syndicatesToShow.length > 0 ? syndicatesToShow.map((syndicate, index) =>
                <Syndicate showLive={googleId !== undefined} addFile={addFile} handleOpen={handleClickOpen} syndicate={syndicate} index={index} key={"syndicate" + index} />

              ) :
                <>
                  <Skeleton sx={{ marginTop: '40px', marginLeft: '20px', marginRight: '20px' }} variant="rounded" width={300} height={800} />
                  <Skeleton sx={{ marginTop: '40px', marginLeft: '20px', marginRight: '20px' }} variant="rounded" width={300} height={800} />
                  <Skeleton sx={{ marginTop: '40px', marginLeft: '20px', marginRight: '20px' }} variant="rounded" width={300} height={800} />
                </>}
            </Grid>

            <br></br>
            <Typography variant="h5">Vad är andelsspel?</Typography>
            <br></br>
            <Typography variant="body">
              <p>Andelsspel är en form av spel där flera individer går samman för att satsa gemensamt på ett lotteri, en spelkupong eller vadslagning för att öka sina chanser att vinna och samtidigt dela på kostnaderna. Det är en populär metod som används inom olika former av hasardspel och vadslagning, inklusive lotterier, trav- och sportspel.</p>

              <p>Viktiga aspekter av andelsspel:</p>
              <ol>
                <li>Gemensam insats: Deltagarna i andelsspelet bidrar med pengar eller insatser som används för att köpa lotter eller spelkuponger. Dessa pengar samlas in och används för att göra flera satsningar eller köpa flera lotterirader.</li>
                <li>Delade vinster: Om andelsspelet resulterar i en vinst, delas vinsten proportionellt mellan deltagarna baserat på deras ursprungliga insatser. Detta innebär att om någon vinst inträffar, fördelas pengarna jämnt eller enligt en förutbestämd fördelningsplan.</li>
                <li>Ökad chans att vinna: Eftersom andelsspel möjliggör fler satsningar eller lotterirader än vad en enskild spelare skulle ha råd med, ökar chanserna att vinna. Det gör det mer prisvärt och tilltalande för människor som inte vill riskera så mycket pengar på egen hand.</li>
                <li>Organiserat spel: Andelsspel kräver vanligtvis organisation och samarbete mellan deltagarna. Det är viktigt att fastställa regler och fördelningsplaner i förväg för att undvika konflikter eller missförstånd senare.</li>
              </ol>
            </Typography>


          </Paper>
        </Grid>

        <Grid item>
          <BannerColumn />
        </Grid>


      </Grid>
      <SyndicateDialog open={dialogOpen} onClose={handleClose} ></SyndicateDialog>
      <SyndicateLiveDialog open={liveDialogOpen} onClose={handleClose} ></SyndicateLiveDialog>
    </Container>
  );
}



