import { Bonus } from "../model/bonus";

export const Bonuses: Bonus[] = [
    {
        bookmaker: "Videoslots",
        image: "/images/videoslots.jpg",
        turnover: 35,
        lowOdds: 0,
        minDeposit: 100,
        title: "100% upp till 2000 SEK + 11 Freespins",
        link: "https://trk.affiliates.videoslots.com/o/gm1iLE?site_id=7010394",
        height: 100,
        width: 200,
    },
    {
        bookmaker: "Mr Vegas",
        image: "/images/mr_vegas.jpg",
        turnover: 35,
        lowOdds: 0,
        minDeposit: 100,
        title: "100% upp till 2000 SEK + 11 Freespins",
        link: "https://trk.affiliates.mrvegas.com/o/duMzFb?site_id=7010394",
        height: 100,
        width: 200,
    },
    {
        bookmaker: "Kungaslottet",
        image: "/images/kungaslottet.png",
        turnover: 125,
        lowOdds: 0,
        minDeposit: 100,
        title: "20000 SEK + 150 Freespins",
        link: "https://trk.affiliates.videoslots.com/o/MMwQXi?site_id=7010394",
        height: 100,
        width: 200,
    },
    {
        bookmaker: "ComeOn",
        image: "/images/comeon.png",
        turnover: 6,
        lowOdds: 1.8,
        minDeposit: 100,
        title: "Sätt in 500 kr – Betta för 1000 kr",
        link: "https://media.comeon.com/tracking.php?tracking_code&aid=120009&mid=1770&sid=458933&pid=476",
        height: 50,
        width: 100,
    }

];

