export const Bookmakers = [      
    {
      bookmaker: "ComeOn",
      image: "./images/comeon.jpg",      
      link: "https://media.comeon.com/tracking.php?tracking_code&aid=120009&mid=2797&sid=458933&pid=874",
      height: 30,
      width: 60,
    }
  ];


  