export interface BlogPost {
    id: number;
    title: string;
    summary: string;
    status: string;
    visibility: string;
    publishedAt: Date;
    updatedAt: Date;
    author: Author;
    categoryImage: string;
    slug:string;
    categories: BlogCategory[];
}

export interface BlogData {
    posts: BlogPost[];
    pagination: Pagination
}

export interface BlogResponse{
        data: BlogData;    
}

export interface Pagination{
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
}

export interface BlogPostResponse{
    success: boolean;
    data: BlogPostData;
}

export interface BlogPostData{
    post: BlogPostPost;
}

export interface BlogPostPost{
    id: number;
    title: string;
    summary: string;
    status: string;
    visibility: string;
    publishedAt: Date;
    updatedAt: Date;
    content: string;
    author: Author;
    categoryImage: string;
    slug:string;
    keyword:string;
    categories: BlogCategory[];
}

export interface Author{
    id: number;
    name: string;
    slug: string;
    photo: string;
    bio: string;
}

export interface BlogCategoriesResponse{
    data: BlogCategoryData;
}

export interface BlogCategoryData{
    categories: BlogCategory[];
}

export interface BlogCategory{
    title: string;
    slug: string;
    background_image: string;
    id: number;
}

export enum BlogStatus{
    PUBLISHED = 'published',
    DRAFT = 'draft'
}

export enum BlogFilter{
    POOL = 'pool',
    TRAV = 'trav',
    SPORT = 'sport',
    ALL = 'all'
}