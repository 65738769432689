import React, { } from 'react';
import Paper from '@mui/material/Paper';
import '..//App.css';
import { Alert, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Coupon from './Coupon';
import { Box } from '@mui/system';
import TopBanner from './TopBanner';
import Moment from 'moment';
import FixtureView from './FixtureView';
import EmptyCoupon from './EmptyCoupon';
import NewCoupon from './NewCoupon';
import { getOddsForMultiple } from '../common/BetslipApi';

export default function Odds(props) {
  const [legs, setLegs] = React.useState([]);
  const [stateId, setStateId] = React.useState(0);
  const [multipleMarketOdds, setMultipleMarketOdds] = React.useState([]);

  let coupons = props.coupons;

  let debug = props.debug;

  let activeCoupons = coupons.filter(c => !c.finished || Moment(c.deadline).add(1, 'days') > Moment());

  function addLeg(game, selectionTypeId) {

    let old = legs.filter(l => l.FixtureId === game.id);

    if (old.length > 0) {
      let index = legs.indexOf(old[0]);
      if (index !== -1) {
        legs.splice(index, 1);
      }
    }

    switch (selectionTypeId) {
      case 1:
        game.selection = game.homeTeam.name;
        break;
      case 2:
        game.selection = "Oavgjort";
        break;
      case 3:
        game.selection = game.awayTeam.name;
        break;
      default:
    }

    let leg = {
      FixtureId: game.id,
      SelectionTypeId: selectionTypeId,
      game: game
    }

    legs.push(leg);
    setLegs(legs);
    setStateId(stateId + 1);
    getOddsForMultiple(setMultipleMarketOdds, legs);
  }

  function clearLegs() {
    setLegs([]);
    setStateId(stateId + 1);
    setMultipleMarketOdds([]);
  }


  return (
    <Box>
      <TopBanner />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        {props.profile && props.profile.Name ?
          <></> :
          <Grid item xs={12} >
            <Alert severity="info">Logga in för att komma åt dina spel på alla enheter</Alert>
          </Grid>
        }
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3} id="coupons">

              {/* Mina kuponger */}

              {activeCoupons.length > 0 ?

                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5">Mina spel</Typography>
                    <div className='coupon-holder'>
                      {activeCoupons.map((coupon, index) => <Coupon coupon={coupon} index={index} removeCoupon={props.removeCoupon} key={"coupon" + index} profile={props.profile} />)}

                    </div>
                  </Paper>
                </Grid> : <></>
              }

              {/* Fixture View */}
              <Grid item xs={12} id="fixtures">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5">Kommande matcher</Typography>
                  <div>
                    <FixtureView debug={debug} addLeg={addLeg} legs={legs} />
                  </div>
                </Paper>
              </Grid>             

            </Grid>
          </Grid>

          {/* Skapa kupong */}
          <Grid item xs={12} sm={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
              <Grid container justifyContent="center">
                {legs.length > 0 ?
                  <NewCoupon legs={legs} addCoupon={props.addCoupon} profile={props.profile} multipleMarketOdds={multipleMarketOdds} clearLegs={clearLegs} />
                  :
                  <EmptyCoupon />
                }                
              </Grid>              
            </Paper>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}



