import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Syndicate, SyndicateResponse } from '../model/syndicate';
import { getSyndicates } from '../common/BetslipApi';
import SyndicateItem from './SyndicateItem';
import { BlogFilter } from '../model/blog';

export default function SyndicateGrid({ numPosts, filter }:
  { numPosts: number, filter: BlogFilter }) {

  let emptySyndicates: SyndicateResponse = { agents: [] };

  let [response, setResponse] = useState(emptySyndicates);

  useEffect(() => {
    getSyndicates(setResponse);
  }, []);

  if (response.agents.length === 0) {
    return <></>;
  }

  let allSyndicates: Syndicate[] = []

  for (let index = 0; index < response.agents.length; index++) {

    if (filter === BlogFilter.POOL && response.agents[index].marketPlaceName.toLowerCase().indexOf("svenska") === -1)
      continue;

    if (filter === BlogFilter.TRAV && response.agents[index].marketPlaceName.toLowerCase().indexOf("atg") === -1)
      continue;

    response.agents[index].syndicate.forEach(element => allSyndicates.push(element));
  }

  let syndicatesToShow = allSyndicates.filter(p => new Date(p.deadline) > new Date());
  syndicatesToShow = syndicatesToShow.filter(p => p.numSharesSold < p.totalShares);
  syndicatesToShow = syndicatesToShow.splice(0, numPosts);
  syndicatesToShow = syndicatesToShow.sort((a, b) => {
    if (new Date(a.deadline) < new Date(b.deadline))
      return -1;
    return 1;
  })

  return (
    <Grid container direction="column" spacing={2} sx={{ justifyContent: "center", alignItems: "center", padding: "20px" }}>
      {syndicatesToShow.map((syndicate, index) =>
        <Grid item key={"displayGrid" + index} sx={{ minWidth: '320px', maxWidth: '320px' }} >
          <SyndicateItem syndicate={syndicate} />
        </Grid>)
      }
    </Grid>
  );
}



