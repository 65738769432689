import { Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

export default function Terms(props) {

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>Villkor för Spelvinnare.se - Oddsjämförelse, Ligor, Användarvillkor</title>
        <meta name="description" content="Villkor för Spelvinnare.se - hur fungerar oddsjämförelse, vilka ligor finns det odds på, användarvillkor" />
        <meta property="og:url" content="https://www.spelvinnare.se/terms"></meta>
        <meta property="twitter:url" content="https://www.spelvinnare.se/terms"></meta>        
      </Helmet>
      <Grid container spacing={3} id="coupons">
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

            <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Användarvillkor</Typography>
            <br></br>
            <Typography variant="body">
              <Link to="/">Spelvinnare.se</Link> är en oberoende informationskälla ämnad för underhållning och utbildning. På sidan finns
              länkar till företag som erbjuder spel. Spelvinnare.se ansvarar inte för innehållet på dessa sidor. Om du
              har problem med hur mycket du spelar kan du kontakta <a href="https://stodlinjen.se/">Stödlinjen</a>
            </Typography>

            <br></br>
            <Typography variant="h5">Oddsjämförelse</Typography>
            <br></br>
            <Typography variant="body">
              <Link to="/">Spelvinnare.se</Link> innehåller oddsjämförelse med spelbolag som innehar svensk licens. Vi kan inte garantera
              att oddsen som visas alltid finns tillgängliga på spelsiterna eftersom odds kan ändras mycket fort. Vi gör
              vårt bästa för att de uppgifter vi visar på siten ska vara korrekta. Speciellt när vi visar odds på fotboll
              under matchens gång, live betting, kan oddsen ändras snabbt.
            </Typography>

            <br></br>
            <Typography variant="h5">Ligor</Typography>
            <br></br>
            <Typography variant="body">
              Vi eftersträvar en god täckning med odds på de största ligorna såsom Champions League,
              Premier League, La Liga, Serie A, Ligue 1 och Bundesliga. Dessutom finns odds på lägre divisioner med bland
              annat Allsvenskan, Championship, League One och Danska Superligan.
            </Typography>

          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
