import { Alert, Card } from "@mui/material";

export default function EmptyCoupon() {

    return (
        <Card elevation={3} key="emptyCoupon">
            <Alert severity="info">Välj spel i matchlistan för att skapa en kupong</Alert>                        
        </Card>
    );

}