import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Faq(props) {

  return (

    <Grid container spacing={3} id="coupons">
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

          <br></br>
          <Typography variant="h5">Hur kan jag använda Spelvinnare.se?</Typography>
          <br></br>
          <Typography variant="body">
            Spelvinnare.se är ett verktyg du kan använda för att bättre kunna följa dina spel. Lägg upp dina spel här och följ dem sedan med vår 
            livescore. Du kommer att kunna se hur dina chanser att vinna förändras allt eftersom matcherna löper på. Efter att spelen är avgjorda 
            kommer du kunna se hur det gått på <Link to="/report">rapportsidan</Link>. Du kan också få en överblick över var du kan spela till bäst odds med vår oddsjämförelse. 
            Vår oddsjämförelse är just nu utvecklad för fotboll. Du kan också se oddsen när matcherna är pågående, så kallad live betting.
          </Typography>

          <br></br>
          <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Hur uppdateras oddsen i oddsjämförelsen?</Typography>
          <br></br>
          <Typography variant="body">
            Spelvinnare.se samarbetar med de ledande svenska speloperatörerna. På så sätt kan vi koppla upp våra system mot deras och få tillgång till
            uppdaterade odds. Vi hämtar även odds under matchernas gång så att vi har färska liveodds hela tiden. Vi använder denna data för att skapa
            en ständigt uppdaterad bild av marknaden i oddsjämförelsen.
          </Typography>

          <br></br>
          <Typography variant="h5">Hur jämför jag odds för ett multipelspel?</Typography>
          <br></br>
          <Typography variant="body">
            Skapa din kupong genom att välja 1, X eller 2 i matchlistan. I kupongen till höger ser du då längst ner en lista med odds. Listan är sorterad så att du har det högsta oddset först. Om du
            klickar på ett odds kommer du till den speloperatören och kan lägga ditt spel.
          </Typography>

          <br></br>
          <Typography variant="h5">Hur fungerar liverättning för PoolX?</Typography>
          <br></br>
          <Typography variant="body">
            Ladda upp din kupong från PoolX under <Link to="/live">Liverättning</Link> sektionen. Då kan du och dina vänner följa kupongen medan spelet pågår. Du kan hela tiden se dina vinstchanser för kupongen.
          </Typography>

          <br></br>
          <Typography variant="h5">Hur räknas sannolikheten för mitt spel ut?</Typography>
          <br></br>
          <Typography variant="body">
            Våra algoritmer utgår från de odds vi ser i marknaden innan och efter matchen börjar. Varje match är unik och följer sin egen modell. Beroende på tid i matchen, antalet mål och andra
            faktorer räknar vi ut sannolikheten för ditt spel.
          </Typography>

          <br></br>
          <Typography variant="h5">Kan jag följa mina spel på fler enheter?</Typography>
          <br></br>
          <Typography variant="body">
            För att kunna följa dina spel på fler enheter måste du logga in. Logga in med ditt Google konto genom att klicka på inloggningsikonen i titelraden.
          </Typography>

          <br></br>
          <Typography variant="h5">Vilka ligor har ni med i oddsjämförelsen?</Typography>
          <br></br>
          <Typography variant="body">
            Vi eftersträvar en god täckning med odds på de största ligorna såsom Champions League,
            Premier League, La Liga, Serie A, Ligue 1 och Bundesliga. Dessutom finns odds på lägre divisioner med bland
            annat Allsvenskan, Superettan, Championship, League One och Danska Superligan.
          </Typography>

          <br></br>
          <Typography variant="h5">Vilka poolspel finns det i Sverige?</Typography>
          <br></br>
          <Typography variant="body">
            Spelvinnare.se har gjort en sammanställning över de olika poolspel, tips, som finns på den svenska 
            marknaden. Du kan också se historiska resultat för Topptipset, Stryktipset, Europatipset och Big 9. 
            I översikten kan du själv utvärdera var du har bäst möjligheter att vinna. Du hittar 
            <Link to="/pools"> översikten här</Link>
          </Typography>

          <br></br>
          <Typography variant="h5">Hur spelar jag andelsspel?</Typography>
          <br></br>
          <Typography variant="body">
            Spelvinnare.se sätter varje dag ihop nya andelsspel för PoolX. Genom att spela våra andelsspel ökar du dina chanser att vinna och slipper klura själv hur du ska spela. Du hittar våra 
            <Link to="/syndicates"> andelsspel här</Link>
          </Typography>

          <br></br>
          <Typography variant="h5">Var kan jag se de närmaste omgångarna i PoolX?</Typography>
          <br></br>
          <Typography variant="body">
            Spelvinnare.se har satt samman en <Link to="/poolx">lista</Link> över de närmaste omgångarna i PoolX. Du får oddsjämförelsen direkt i kupongen.
          </Typography>

        </Paper>
      </Grid>
    </Grid>
  );
}
