import { Grid, Link } from "@mui/material";
import { Bookmakers } from "../common/Bookmakers";
import '..//App.css';

export default function OddsTableMultiple(props) {

    return (
        <Grid container paddingTop={1} paddingBottom={1} direction="column" className="odds-table">
            {
                props.odds.map((odds, index) => {

                    let bookmaker = Bookmakers.find((b) => b.bookmaker === odds.marketPlaceName);

                    return (
                        <Grid container key={"oddsRow" + index}>
                            {bookmaker
                                ?

                                <Grid item xs={6}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start" height="100%" sx={{ pt: "0px", pb: "0px" }}>
                                        <Link href={bookmaker.link} target="_blank">
                                            <img alt={bookmaker.bookmaker} src={bookmaker.image} width="auto" height={bookmaker.height}></img>
                                        </Link>
                                    </Grid>
                                </Grid>


                                :
                                <Grid item xs={6} key={"name" + index}>{odds.marketPlaceName}</Grid>}

                            <Grid item xs={2} key={"odds" + index}>
                                <Grid container direction="column" justifyContent="center" alignItems="flex-start" height="100%" sx={{ pt: "0px", pb: "0px" }}>
                                    {bookmaker ?
                                        <Link href={bookmaker.link} target="_blank">
                                            <>{odds.odds}</>

                                        </Link> :
                                        <>
                                            {odds.odds}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>);

                })
            }
        </Grid>
    );
}