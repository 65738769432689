import { Card, Grid, Typography, useMediaQuery } from '@mui/material';

export default function Pool(props) {

    const wide = useMediaQuery('(min-width:600px)');

    return (
        <Grid item mt="20px" xs={12}>
            <Card elevation={7} key={props.index + 'coupon'} sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.secondary.main
                        : theme.palette.grey[900]

            }}>

                <Typography
                    component="h5"
                    variant="h6"
                    color="inherit"
                    noWrap
                    ml="20px"
                    mt="20px"
                >
                    {props.pool.name}
                </Typography>

                <Grid container direction={wide ? "row" : "column"}>
                    <Grid item xs={6} pl="20px" pr="20px">
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                    <Grid item className='coupon-row' key='operator'>
                                        <div className='coupon-label'>Operatör:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='min'>
                                        <div className='coupon-label'>Återbetalning:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='num'>
                                        <div className='coupon-label'>Antal matcher:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='turnover'>
                                        <div className='coupon-label'>Möjliga utfall:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='stake'>
                                        <div className='coupon-label'>Insats per rad:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='fund'>
                                        <div className='coupon-label'>Garantifond:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='guarantee'>
                                        <div className='coupon-label'>Garanti:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='terms'>
                                        <div className='coupon-label'>Villkor:</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={9} xs={6}>
                                <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                    <Grid item className='coupon-row' key='operator'>
                                        <div className='coupon-value'>{props.pool.operator}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='min'>
                                        <div className='coupon-value'>{props.pool.payout}</div>

                                    </Grid>
                                    <Grid item className='coupon-row' key='num'>
                                        <div className='coupon-value'>{props.pool.numberOfGames}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='turnover'>
                                        <div className='coupon-value'>{props.pool.outcomes}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='stake'>
                                        <div className='coupon-value'>{props.pool.stakePerRow}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='fund'>
                                        <div className='coupon-value'>{props.pool.fund}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='guarantee'>
                                        <div className='coupon-value'>{props.pool.guarantee}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='terms'>
                                        <div className='coupon-value'>{props.pool.terms}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={6} pl="20px" pr="20px">

                        <Grid container>
                            <Grid item xs={6} sm={3}>
                                <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                    <Grid item className='coupon-row' key='win1'>
                                        <div className='coupon-label'>Vinstgrupp 1:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win2'>
                                        <div className='coupon-label'>Vinstgrupp 2:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win3'>
                                        <div className='coupon-label'>Vinstgrupp 3:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win4'>
                                        <div className='coupon-label'>Vinstgrupp 4:</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win5'>
                                        <div className='coupon-label'>Vinstgrupp 5:</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={9}>
                                <Grid container paddingTop={1} paddingBottom={1} direction="column" >
                                    <Grid item className='coupon-row' key='win1'>
                                        <div className='coupon-label'>{props.pool.win1}<br></br></div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win2'>
                                        <div className='coupon-label'>{props.pool.win2}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win3'>
                                        <div className='coupon-label'>{props.pool.win3}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win4'>
                                        <div className='coupon-label'>{props.pool.win4}</div>
                                    </Grid>
                                    <Grid item className='coupon-row' key='win5'>
                                        <div className='coupon-label'>{props.pool.win5}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>

                {/* <PoolChart wins={props.pool.wins} turnover={props.pool.turnover} name={props.pool.name}/> */}

            </Card>
        </Grid>
    );
}
