import { Collapse, Grid, IconButton, TableCell, TableRow, useMediaQuery } from '@mui/material';
import OddsTable from './OddsTable';
import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { currentStanding } from './LiveCoupon';

function percentFormatter(params) {
    return Math.round(params * 100) + '%';
  }

export default function LiveCouponPoolXFixture(props) {

    const [open, setOpen] = useState(false);
    const wide = useMediaQuery('(min-width:600px)');

    let g = props.game;
    let odds = props.round.fixtures[g.id].odds;

    return (
        <React.Fragment>
            <TableRow key={g.id}>
                <TableCell>{g.gameOrder}</TableCell>
                <TableCell>{g.gameName}</TableCell>
                <TableCell>{currentStanding(g)}</TableCell>
                <TableCell>{g.gameStatus}</TableCell>
                <TableCell>{g.currentSign}</TableCell>
                <TableCell>{percentFormatter(g.pickHome)}</TableCell>
                <TableCell>{percentFormatter(g.pickDraw)}</TableCell>
                <TableCell>{percentFormatter(g.pickAway)}</TableCell>
                <TableCell>{percentFormatter(g.ownHome)}</TableCell>
                <TableCell>{percentFormatter(g.ownDraw)}</TableCell>
                <TableCell>{percentFormatter(g.ownAway)}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
      <TableCell colSpan={9}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container sx={{ marginLeft: '40px' }} direction='row'>
            {odds &&

              <Grid container direction='row'>
                <Grid item sm={4}>
                  <OddsTable odds={odds}></OddsTable>
                </Grid>
                <Grid item sx={{ marginLeft: '40px' }} sm={6}>
                  <div dangerouslySetInnerHTML={{ __html: g.text }} />
                </Grid>
              </Grid>
            }
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
            
        </React.Fragment>
    );
}
