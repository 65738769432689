import { Breadcrumbs, Button, Card, Grid, List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import React from 'react';
import { getFixtures } from '../common/BetslipApi';

function handleClick(item, props) {
  if (props.selectedCountry === undefined) {
    props.setSelectedCountry(item);
    getFixtures(props.setFixtures, item.id, undefined);
  }
  else {
    props.setSelectedLeague(item);
    getFixtures(props.setFixtures, undefined, item.id);
  }
}

function handleHomeClick(props) {
  props.setSelectedCountry(undefined);
  props.setSelectedLeague(undefined);
  getFixtures(props.setFixtures)
}

export default function FixtureMenu(props) {

  let items = props.countries;
  let leagues = props.leagues;

  if (props.selectedCountry !== undefined) {
    items = leagues.filter(l => l.countryId === props.selectedCountry.id);
  }

  let minwidth = "235px";
  if (props.wide) {
    minwidth = "300px";
  }

  return (

    <Card className='fixture' elevation={props.elevation} sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? "#9cd7bc"
          : theme.palette.grey[900]
    }
    }>

      <Grid item xs={12} md={6} >
        <List dense={true} sx={{ minWidth: minwidth, maxWidth: props.wide ? "inherit" : "300px" }}>
          {
            props.selectedCountry ?
              <ListItem >
                <Breadcrumbs sx={{ minWidth: minwidth, fontWeight: 'bold' }} >

                  <Button
                    underline="hover"
                    color="inherit"
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                    onClick={() => handleHomeClick(props)}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>Home</Typography>
                  </Button>

                  {
                    props.selectedCountry ?
                      <Typography sx={{ fontWeight: 'bold' }}>{props.selectedCountry?.name}</Typography>
                      :
                      ""
                  }
                  {
                    props.selectedLeague ?
                      <Typography sx={{ fontWeight: 'bold' }}>{props.selectedLeague?.name}</Typography>
                      :
                      ""
                  }
                </Breadcrumbs>
              </ListItem>
              : <ListItem sx={{ minWidth: minwidth }}>{" "}</ListItem>}
          {items.map((item, index) => {

            let imagePath = item.imagePath ?? item.logoPath;
            let selected = props.selectedCountry !== undefined && item?.id === props.selectedLeague?.id;

            let sx = {};

            if (selected) {
              sx = { fontWeight: 'bold' };
            }

            return (
              <ListItemButton key={index} onClick={() => handleClick(item, props)}>
                <Grid container direction="row" flexWrap="nowrap" sx={{ minwidth: minwidth }}>
                  <Grid item >
                    {
                      imagePath != null ?
                        <ListItemIcon>
                          <img alt="Flag" src={imagePath} width="auto" height="20px"></img>
                        </ListItemIcon>
                        :
                        <ListItemIcon>
                          <img alt="Flag" src="./images/globe.png" width="auto" height="20px"></img>
                        </ListItemIcon>}
                  </Grid>
                  <Grid item>
                    <Typography sx={sx}>{item.name}</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>);
          })}
        </List>
      </Grid>
    </Card >

  );
}
