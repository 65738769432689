import { Button, Container, Grid, Paper, SxProps, Theme, Typography, useMediaQuery, Link, Card, Box } from '@mui/material';
import BannerColumn from './BannerColumn';
import React, { useEffect, useState } from 'react';
import { getBlogPostBySlug } from '../common/BlogApi';
import { BlogPostResponse } from '../model/blog';
import { useParams } from 'react-router-dom';
import BlogAuthor from './BlogAuthor';
import { getFixtures, getOddsForFixture, getSyndicates } from '../common/BetslipApi';
import OddsTable from './OddsTable';
import { Fixture } from '../model/fixture';
import { Helmet } from "react-helmet";
import svenska from "../images/svenskaspel.png";
import atg from "../images/atg.png";
import comeon from "../images/comeon.png";
import { Syndicate, SyndicateResponse } from '../model/syndicate';
import SyndicateItem from './SyndicateItem';

export default function BlogDetail() {

  let [blogPost, setBlogPost] = useState<BlogPostResponse | undefined>(undefined);
  let [loading, setLoading] = useState<boolean>(false);
  let [fixtureOdds, setFixtureOdds] = React.useState([]);
  let [fixtureId, setFixtureId] = React.useState<number | undefined>(undefined);
  const [fixtures, setFixtures] = React.useState<Array<Fixture>>([]);
  let emptySyndicates: SyndicateResponse = { agents: [] };

  let [response, setResponse] = useState(emptySyndicates);

  useEffect(() => {

  }, []);


  const routeParams = useParams();
  let blogPostId: string = "";

  if (routeParams.blogPostId)
    blogPostId = routeParams.blogPostId;

  useEffect(() => {
    console.log('try getBlogPosts');
    if (!loading) {
      setLoading(true);
      getBlogPostBySlug(setBlogPost, blogPostId);
      //getBlogPost(setBlogPost, blogPostId);
    }
    if (fixtureId !== undefined) {
      getOddsForFixture(setFixtureOdds, fixtureId)
      getFixtures(setFixtures, undefined, undefined, fixtureId);
    }
    getSyndicates(setResponse);

  }, [loading, blogPostId, fixtureId, fixtures.length]);

  const wide = useMediaQuery('(min-width:600px)');

  let stylePaper: SxProps<Theme> = {
    padding: "10px",
    margin: "30px",
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.grey[900]
  };

  let allSyndicates: Syndicate[] = []

  for (let index = 0; index < response.agents.length; index++) {
    response.agents[index].syndicate.forEach(element => allSyndicates.push(element));
  }

  let contentToShow = blogPost && blogPost.data.post.content;

  const preRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)".*?<\/a>/gi;
  let objectsToShow = [];

  if (contentToShow && contentToShow?.match(preRegex)) {

    let match: string[] | null;
    let currentIndex = 0;
    while ((match = preRegex.exec(contentToShow)) !== null) {

      const preStartIndex = contentToShow?.indexOf(match[0]);
      let textBefore = contentToShow?.substring(currentIndex, preStartIndex);

      if (textBefore) {
        objectsToShow.push(<Grid key={currentIndex + 'text'} item>
          <div dangerouslySetInnerHTML={{ __html: textBefore }} />
        </Grid>)
      }

      let logoImage = "";
      let width = "137.76px";
      let height = "40px";
      let url = match[1];

      let foundSyndicate = allSyndicates.find(s => s.url.replaceAll("/", "") === url.replaceAll("/", ""));

      if (url.indexOf("svenskaspel") > -1) {
        logoImage = `url(${svenska})`;
        let parts = url.split('/');
        let matchUrl = parts[parts.length - 1];
        foundSyndicate = allSyndicates.find(s => s.url.indexOf(matchUrl) > -1);
      }
      else if (url.indexOf("atg") > -1) {
        logoImage = `url(${atg})`;
        width = "120px";
      }
      else if (url.indexOf("comeon") > -1) {
        logoImage = `url(${comeon})`;
        width = "120px";

        const baseURL = "https://media.comeon.com/tracking.php?tracking_code&aid=120009&mid=2797&sid=458933&pid=874";
        const encodedQueryParam = encodeURIComponent(url);
        url = `${baseURL}&url=${encodedQueryParam}`;
      }

      if (foundSyndicate) {
        objectsToShow.push(
          <SyndicateItem syndicate={foundSyndicate} />
        )
      }
      else {

        objectsToShow.push(

          <Grid container key={currentIndex + 'link'} direction="column" alignItems="center" width="100%">
            <Card elevation={7} sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.secondary.main
                  : theme.palette.grey[900],

              maxWidth: "500px",
              minWidth: "500px",
              padding: "20px"
            }}>

              <Grid container direction="column" alignItems="center">
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                  <Box sx={{ width: { width }, height: { height }, backgroundSize: "cover", backgroundImage: logoImage }}></Box>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Link href={url} target="_blank">
                        <Button variant="contained" sx={{ margin: "10px" }}>
                          Spela Här
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                  18+ Spela ansvarsfullt <a href="https://stodlinjen.se/">Stödlinjen</a>
                </Typography>
              </Grid>
            </Card>
          </Grid>

        )
      }

      currentIndex = preStartIndex + match[0].length;
    }
    if (contentToShow) {
      objectsToShow.push(<Grid item>
        <div dangerouslySetInnerHTML={{ __html: contentToShow.substring(currentIndex) }} />
      </Grid>)
    }

  }
  else if (contentToShow) {

    let myTag = <Grid item>
      <div dangerouslySetInnerHTML={{ __html: contentToShow }} />
    </Grid>

    objectsToShow.push(myTag)
  }


  let fixture = fixtures.filter(f => f.id === fixtureId);

  let title = "Spelvinnare.se - Oddsjämförelse, Liverättning, PoolX, Tips";
  let descr = "Speltips, oddsjämförelse för multiplar, bonusar hos ledande speloperatörer, liverättning, livescore, betting, sportsbetting, PoolX";

  if (blogPost !== undefined) {
    title = "Spelvinnare.se - " + blogPost.data.post.title;
    if (blogPost.data.post.keyword !== null && blogPost.data.post.keyword.length > 1) {
      descr = blogPost.data.post.keyword
    }
  }

  return (

    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={descr} />
      </Helmet>

      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

        <Grid container id="coupons" direction={wide ? "row" : "column"}>
          <Grid item xs={9}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
              <Grid container  >
                {blogPost && blogPost.success && blogPost.data && blogPost.data.post && contentToShow ?
                  <Grid container direction="column" >
                    <Grid item>
                      <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>{blogPost.data.post.title}</Typography>
                    </Grid>
                    {objectsToShow.length > 0 && objectsToShow.map(o => o)}
                    {fixture.length === 1 &&
                      <Paper sx={stylePaper}>
                        <Grid container justifyContent="center">
                          <Typography variant='h5'>{fixture[0].name}</Typography>
                          <OddsTable odds={fixtureOdds} />
                        </Grid>
                      </Paper>}
                    <Grid item>
                      <BlogAuthor name={blogPost.data.post.author.name} bio={blogPost.data.post.author.bio} photo={blogPost.data.post.author.photo} />
                    </Grid>
                  </Grid>
                  :
                  <Typography variant='h4'>Loading...</Typography>}
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <BannerColumn />
          </Grid>
        </Grid>

      </Container>
    </>
  );
}




